<template>
  <div :id="id" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[id]"
      @ok="ok" @hidden="hidden">

      <AlertFeedback class="staff-alert" v-if="alertMsg != null" :msg="alertMsg" :details="alertMsgDetails.list" :detailTitle="alertMsgDetails.title" :alertState="alertState" @resetAlert="resetAlert"/>
      <b-form-checkbox v-if="projectIds.length !== 0" class="bookings-switch" v-model="bookings" id="bookings-switch" name="bookings-switch " switch @change="onBookings">{{ $t('staff.project_bookings') }}</b-form-checkbox>
      
      <div class="selector-navbar">
        <PriorityNavigation class="selector-nav" ref="selector-nav" ulClassName="nav nav-pills">
          <li name="list" class="nav-pills nav-link active" @click.stop="selectorNavClick">
            <a href="#" target="_self">{{  allowSelect && listSelectedCount > 0? $t('staff.selector.list_w_count', { count: listSelectedCount }) : $t('staff.selector.list') }}</a>
          </li>
          <li name="recommended" class="nav-pills nav-link" v-if="this.taskUuId != null" @click.stop="selectorNavClick">
            <a href="#" target="_self">{{ allowSelect && recommendedSelectedCount > 0? $t('staff.selector.recommended_w_count', { count: recommendedSelectedCount }) : $t('staff.selector.recommended') }}</a>
          </li>
          <li name="orgChart" class="nav-pills nav-link" @click.stop="selectorNavClick">
            <a href="#" target="_self">{{ allowSelect && orgChartSelectedCount > 0? $t('staff.selector.orgChart_w_count', { count: orgChartSelectedCount }) : $t('staff.selector.orgChart') }}</a>
          </li>
          <li name="generic" class="nav-pills nav-link" @click.stop="selectorNavClick">
            <a href="#" target="_self">{{ allowSelect && genericSelectedCount > 0? $t('staff.selector.generic_w_count', { count: genericSelectedCount }) : $t('staff.selector.generic') }}</a>
          </li>
        </PriorityNavigation>
      </div>

      <b-tabs v-model="activeTab" @activate-tab="onTabSwitch"
        class="staff-tab-container"
        active-nav-item-class="active"
        content-class="staff-tabs mt-3 specific-list" pills>
        
        <b-tab title="List" title-item-class="mytab">
          <ListFilter :termOnly="true" :term="searchFilterTerm" @applyFilter="applyFilter"/>
          <PriorityNavigation class="list-toolbar grid-toolbar border" v-if="allowManage">
            <li v-if="!enablePagination" class="d-inline-block">
              <b-form-checkbox :id="`BTN_SELECT_${id}`" class="select-state" v-model="select_state.checked" :indeterminate="select_state.indeterminate" @change="selectionChanged"></b-form-checkbox>
              <b-popover :target="`BTN_SELECT_${id}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('button.select') }}
              </b-popover>
            </li>
            <li v-if="canAdd()" :id="`BTN_ADD_${id}`">
              <b-btn @click="modalOpen(true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
              <b-popover :target="`BTN_ADD_${id}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('button.add') }}
              </b-popover>
            </li>
            
            <li v-if="canView()" :id="`BTN_EDIT_${id}`">
              <b-btn :disabled="disableEdit" @click="modalOpen(false, selected)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
              <b-popover :target="`BTN_EDIT_${id}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('button.edit') }}
              </b-popover>
            </li>
            <li v-if="canAdd()" :id="`BTN_DUPLICATE_${id}`">
              <b-btn :disabled="disableDuplicate" @click="showDuplicateDialog(selected)"><font-awesome-icon :icon="['far','clone']"/></b-btn>
              <b-popover :target="`BTN_DUPLICATE_${id}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('button.duplicate') }}
              </b-popover>
            </li>
            <li  v-if="canDelete()" :id="`BTN_DELETE_${id}`">
              <b-btn :disabled="disableDelete" @click="rowDelete(selected)"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
              <b-popover :target="`BTN_DELETE_${id}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('button.delete') }}
              </b-popover>
            </li>
            <li v-if="canAdd()" :id="`BTN_IMPORT_DOCUMENT_${id}`">
              <b-btn @click="fileImport"><font-awesome-icon :icon="['far', 'inbox-in']"/></b-btn>
              <b-popover :target="`BTN_IMPORT_DOCUMENT_${id}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('staff.button.import_document') }}
              </b-popover>
            </li>
            <li :id="`BTN_EXPORT_DOCUMENT_${id}`">
              <b-btn @click="fileExport"><font-awesome-icon :icon="['far', 'inbox-out']"/></b-btn>
              <b-popover :target="`BTN_EXPORT_DOCUMENT_${id}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('staff.button.export_document') }}
              </b-popover>
            </li>
            <li @[colorMouseEnterEvent]="onColoringOver" @mouseleave="onColoringLeave">
              <b-dropdown :id="`BTN_COLORING_${id}`" ref="coloring" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <font-awesome-icon :icon="['far', 'palette']"/>
                </template>
                <b-dropdown-group :header="$t('colorby')">
                  <b-dropdown-item @click="onColorChange('none', 'staff_selector_coloring')" href="#">
                    <span class="action-item-label">{{ $t('none') }}</span><font-awesome-icon class="active-check" v-if="coloring.none" :icon="['far', 'check']"/>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onColorChange('staff', 'staff_selector_coloring')" href="#">
                    <span class="action-item-label">{{ $t('staff.coloring.staff') }}</span><font-awesome-icon class="active-check" v-if="coloring.staff" :icon="['far', 'check']"/>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onColorChange('company', 'staff_selector_coloring')" href="#">
                    <span class="action-item-label">{{ $t('staff.coloring.company') }}</span><font-awesome-icon class="active-check" v-if="coloring.company" :icon="['far', 'check']"/>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onColorChange('department', 'staff_selector_coloring')" href="#">
                    <span class="action-item-label">{{ $t('staff.coloring.department') }}</span><font-awesome-icon class="active-check" v-if="coloring.department" :icon="['far', 'check']"/>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onColorChange('location', 'staff_selector_coloring')" href="#">
                    <span class="action-item-label">{{ $t('staff.coloring.location') }}</span><font-awesome-icon class="active-check" v-if="coloring.location" :icon="['far', 'check']"/>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onColorChange('skill', 'staff_selector_coloring')" href="#">
                    <span class="action-item-label">{{ $t('staff.coloring.skill') }}</span><font-awesome-icon class="active-check" v-if="coloring.skill" :icon="['far', 'check']"/>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onColorChange('resource', 'staff_selector_coloring')" href="#">
                    <span class="action-item-label">{{ $t('staff.coloring.resource') }}</span><font-awesome-icon class="active-check" v-if="coloring.resource" :icon="['far', 'check']"/>
                  </b-dropdown-item>
                </b-dropdown-group>
              </b-dropdown>
            </li>
            <li>
              <span readonly class="action-toolbar-divider">|</span>
            </li>
            <li>
              <b-form-checkbox v-model="activeStaff" @change="onActiveStaffChange">{{$t('staff.toggle_active')}}</b-form-checkbox>
              <b-form-checkbox v-model="inactiveStaff" @change="onInactiveStaffChange" class='ml-2'>{{$t('staff.toggle_inactive')}}</b-form-checkbox>
            </li>
          </PriorityNavigation>

          <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height-with-tabs" id="staff-grid"
                :gridOptions="gridOptions"
                @grid-ready="onGridReady"
                :columnDefs="columnDefs"
                :context="context"
                :defaultColDef="defaultColDef"
                :pagination="enablePagination"
                :paginationPageSize="1000"
                :cacheBlockSize="10000"
                :rowData="rowData"
                rowModelType="serverSide"
                :rowSelection="multiple? 'multiple':'single'"
                rowMultiSelectWithClick
                
                :overlayLoadingTemplate="overlayLoadingTemplate"
                :serverSideInfiniteScroll="true"
                :sideBar="false"
                suppressDragLeaveHidesColumns
                suppressCellFocus
                suppressContextMenu
                suppressMultiSort
                
                noRowsOverlayComponent="noRowsOverlay"
                :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
                >
          </ag-grid-vue>
          <!-- :overlayNoRowsTemplate="overlayNoRowsTemplate" -->
        </b-tab>

        <b-tab v-if="taskUuId !== null" title="Recommended" title-item-class="mytab" content-class="recommended-list">
          <ListFilter :termOnly="true" :term="recommended.searchFilterTerm" @applyFilter="applyRecommendedFilter"/>
          <div class="grid-toolbar border">
            <b-btn id="recommendation_settings" class="settings-button" @click="showRecommendationSettings()"><font-awesome-icon :icon="['far', 'gear']"/></b-btn>
            <b-popover target="recommendation_settings" triggers="hover" placement="top">
              {{ $t('staff.recommendation_settings') }}
            </b-popover>
          </div>
    
          <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height-with-tabs" id="recommended-grid"
                :gridOptions="recommended.gridOptions"
                @grid-ready="onRecommendedGridReady"
                :columnDefs="recommended.columnDefs"
                :context="recommended.context"
                :defaultColDef="recommended.defaultColDef"
                pagination
                :paginationPageSize="1000"
                rowModelType="serverSide"
                :rowSelection="multiple? 'multiple':'single'"
                rowMultiSelectWithClick
                
                :overlayLoadingTemplate="overlayLoadingTemplate"
                :serverSideInfiniteScroll="true"
                :sideBar="false"
                suppressDragLeaveHidesColumns
                suppressCellFocus
                suppressMultiSort

                noRowsOverlayComponent="noRowsOverlay"
                :noRowsOverlayComponentParams="generic.noRowsOverlayComponentParams"
                >
                <!-- :overlayNoRowsTemplate="overlayNoRowsTemplate" -->
          </ag-grid-vue>
        </b-tab>

        <b-tab title="Org Chart" title-item-class="mytab" content-class="orgchart-list">
          <ListFilter :termOnly="true" :term="orgChart.searchFilterTerm" @applyFilter="applyOrgChartFilter"/>
          <PriorityNavigation class="orgchart-toolbar grid-toolbar border" v-if="allowManage">
            <li :id="`BTN_ADD_${id}_ORGCHART`">
              <b-btn v-if="canAdd()" @click="modalOpen(true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
              <b-popover :target="`BTN_ADD_${id}_ORGCHART`" triggers="hover" placement="top">
                {{ $t('button.add') }}
              </b-popover>
            </li>
            <li v-if="canView()" :id="`BTN_EDIT_${id}_ORGCHART`">
              <b-btn :disabled="orgChart.disableEdit" @click="modalOpen(false, orgChart.selected)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
              <b-popover :target="`BTN_EDIT_${id}_ORGCHART`" triggers="hover" placement="top">
                {{ $t('button.edit') }}
              </b-popover>
            </li>
            <li v-if="canAdd()" :id="`BTN_DUPLICATE_${id}_ORGCHART`">
              <b-btn :disabled="orgChart.disableDuplicate" @click="showDuplicateDialogSelectedObj(orgChart.selected, orgChart.gridOptions.api)"><font-awesome-icon :icon="['far','clone']"/></b-btn>
              <b-popover :target="`BTN_DUPLICATE_${id}_ORGCHART`" triggers="hover" placement="top">
                {{ $t('button.duplicate') }}
              </b-popover>
            </li>
            <li v-if="canDelete()" :id="`BTN_DELETE_${id}_ORGCHART`">
              <b-btn :disabled="orgChart.disableDelete" @click="rowDelete(orgChart.selected)"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
              <b-popover :target="`BTN_DELETE_${id}_ORGCHART`" triggers="hover" placement="top">
                {{ $t('button.delete') }}
              </b-popover>
            </li>
            <li :id="`BTN_EXPORT_DOCUMENT_${id}_ORGCHART`">
              <b-btn @click="fileExportOrgChart"><font-awesome-icon :icon="['far', 'inbox-out']"/></b-btn>
              <b-popover :target="`BTN_EXPORT_DOCUMENT_${id}_ORGCHART`" triggers="hover" placement="top">
                {{ $t('staff.button.export_document') }}
              </b-popover>
            </li>
            <li>
              <span readonly class="action-toolbar-divider">|</span>
            </li>
            <li>
                <b-form-checkbox v-model="orgChartRealStaff" @change="onOrgChartRealStaffChange" class='ml-2'>{{$t('staff.actual_staff')}}</b-form-checkbox>
                <b-form-checkbox v-model="orgChartGenericStaff" @change="onOrgChartGenericStaffChange" class='ml-2'>{{$t('staff.generic_staff')}}</b-form-checkbox>
                <b-form-checkbox :disabled="!orgChartRealStaff" v-model="orgChartActiveStaff" @change="onOrgChartActiveStaffChange" class='ml-2'>{{$t('staff.toggle_active')}}</b-form-checkbox>
                <b-form-checkbox :disabled="!orgChartRealStaff" v-model="orgChartInactiveStaff" @change="onOrgChartInactiveStaffChange" class='ml-2'>{{$t('staff.toggle_inactive')}}</b-form-checkbox>
            </li>
          </PriorityNavigation>
          
          <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height-with-tabs" id="org-chart-grid"
                :gridOptions="orgChart.gridOptions"
                @grid-ready="onOrgChartGridReady"
                :columnDefs="orgChart.columnDefs"
                :autoGroupColumnDef="orgChart.autoGroupColumnDef"
                :context="orgChart.context"
                :defaultColDef="orgChart.defaultColDef"
                :rowData="orgChart.rowData"
                :rowSelection="multiple? 'multiple':'single'"
                rowMultiSelectWithClick
                
                :overlayLoadingTemplate="overlayLoadingTemplate"
                :sideBar="false"
                groupDefaultExpanded="-1"
                suppressDragLeaveHidesColumns
                suppressCellFocus
                treeData
                suppressContextMenu
                suppressMultiSort

                noRowsOverlayComponent="noRowsOverlay"
                :noRowsOverlayComponentParams="orgChart.noRowsOverlayComponentParams"
                >
                <!-- :overlayNoRowsTemplate="overlayNoRowsTemplate" -->
          </ag-grid-vue>
        </b-tab>

        <b-tab title="Generic" title-item-class="mytab">
          <ListFilter :termOnly="true" :term="generic.searchFilterTerm" @applyFilter="applyGenericFilter"/>
          <div class="grid-toolbar border" v-if="allowManage">
            <div v-if="!enablePagination" class="d-inline-block">
              <b-form-checkbox :id="`GENERIC_BTN_SELECT_${id}`" class="select-state" v-model="generic.select_state.checked" :indeterminate="generic.select_state.indeterminate" @change="genericSelectionChanged"></b-form-checkbox>
              <b-popover :target="`GENERIC_BTN_SELECT_${id}`" triggers="hover" placement="top">
                {{ $t('button.select') }}
              </b-popover>
            </div>
            <span v-if="canAdd()" :id="`GENERIC_BTN_ADD_${id}`">
              <b-btn @click="modalOpen(true, null, true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
              <b-popover :target="`GENERIC_BTN_ADD_${id}`" triggers="hover" placement="top">
                {{ $t('button.add') }}
              </b-popover>
            </span>
            <span v-if="canView()" :id="`GENERIC_BTN_EDIT_${id}`">
              <b-btn :disabled="generic.disableEdit" @click="modalOpen(false, generic.selected, true)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
              <b-popover :target="`GENERIC_BTN_EDIT_${id}`" triggers="hover" placement="top">
                {{ $t('button.edit') }}
              </b-popover>
            </span>
            <span v-if="canAdd()" :id="`GENERIC_BTN_DUPLICATE_${id}`">
              <b-btn :disabled="generic.disableDuplicate" @click="showDuplicateDialogForGeneric(generic.selected)"><font-awesome-icon :icon="['far','clone']"/></b-btn>
              <b-popover :target="`GENERIC_BTN_DUPLICATE_${id}`" triggers="hover" placement="top">
                {{ $t('button.duplicate') }}
              </b-popover>
            </span>
            <span  v-if="canDelete()" :id="`GENERIC_BTN_DELETE_${id}`">
              <b-btn :disabled="generic.disableDelete" @click="rowDelete(generic.selected)"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
              <b-popover :target="`GENERIC_BTN_DELETE_${id}`" triggers="hover" placement="top">
                {{ $t('button.delete') }}
              </b-popover>
            </span>
            <span v-if="canAdd()" :id="`GENERIC_BTN_IMPORT_DOCUMENT_${id}`">
              <b-btn @click="fileImportGeneric"><font-awesome-icon :icon="['far', 'inbox-in']"/></b-btn>
              <b-popover :target="`GENERIC_BTN_IMPORT_DOCUMENT_${id}`" triggers="hover" placement="top">
                {{ $t('staff.button.import_document') }}
              </b-popover>
            </span>
            <span :id="`GENERIC_BTN_EXPORT_DOCUMENT_${id}`">
              <b-btn @click="fileExportGeneric"><font-awesome-icon :icon="['far', 'inbox-out']"/></b-btn>
              <b-popover :target="`GENERIC_BTN_EXPORT_DOCUMENT_${id}`" triggers="hover" placement="top">
                {{ $t('staff.button.export_document') }}
              </b-popover>
            </span>
            <span @[colorMouseEnterEvent]="onColoringOverGeneric" @mouseleave="onColoringLeaveGeneric">
              <b-dropdown :id="`BTN_COLORING_${id}`" ref="coloring_generic" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <font-awesome-icon :icon="['far', 'palette']"/>
                </template>
                <b-dropdown-group :header="$t('colorby')">
                  <b-dropdown-item @click="onColorChange('none', 'staff_selector_generic_coloring')" href="#">
                    <span class="action-item-label">{{ $t('none') }}</span><font-awesome-icon class="active-check" v-if="generic.coloring.none" :icon="['far', 'check']"/>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onColorChange('staff', 'staff_selector_generic_coloring')" href="#">
                    <span class="action-item-label">{{ $t('staff.coloring.staff') }}</span><font-awesome-icon class="active-check" v-if="generic.coloring.staff" :icon="['far', 'check']"/>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onColorChange('company', 'staff_selector_generic_coloring')" href="#">
                    <span class="action-item-label">{{ $t('staff.coloring.company') }}</span><font-awesome-icon class="active-check" v-if="generic.coloring.company" :icon="['far', 'check']"/>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onColorChange('location', 'staff_selector_generic_coloring')" href="#">
                    <span class="action-item-label">{{ $t('staff.coloring.location') }}</span><font-awesome-icon class="active-check" v-if="generic.coloring.location" :icon="['far', 'check']"/>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onColorChange('skill', 'staff_selector_generic_coloring')" href="#">
                    <span class="action-item-label">{{ $t('staff.coloring.skill') }}</span><font-awesome-icon class="active-check" v-if="generic.coloring.skill" :icon="['far', 'check']"/>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onColorChange('resource', 'staff_selector_generic_coloring')" href="#">
                    <span class="action-item-label">{{ $t('staff.coloring.resource') }}</span><font-awesome-icon class="active-check" v-if="generic.coloring.resource" :icon="['far', 'check']"/>
                  </b-dropdown-item>
                </b-dropdown-group>
              </b-dropdown>
            </span>
          </div>
    
          <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height-with-tabs" id="generic-staff-grid"
                :gridOptions="generic.gridOptions"
                @grid-ready="onGenericReady"
                :columnDefs="generic.columnDefs"
                :context="generic.context"
                :defaultColDef="generic.defaultColDef"
                :pagination="enablePagination"
                :paginationPageSize="1000"
                rowModelType="serverSide"
                :rowSelection="multiple? 'multiple':'single'"
                rowMultiSelectWithClick
                
                :overlayLoadingTemplate="overlayLoadingTemplate"
                :serverSideInfiniteScroll="true"
                :sideBar="false"
                suppressDragLeaveHidesColumns
                suppressCellFocus
                suppressContextMenu
                suppressMultiSort

                noRowsOverlayComponent="noRowsOverlay"
                :noRowsOverlayComponentParams="generic.noRowsOverlayComponentParams"
                >
                <!-- :overlayNoRowsTemplate="overlayNoRowsTemplate" -->
          </ag-grid-vue>
        </b-tab>
      </b-tabs>
        
      <template v-slot:modal-footer="{ ok, cancel }">
        <template v-if="allowSelect">
          <b-button :disabled="disableOk && !allowNone" size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('MANAGE' === mode?'button.close':'button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <StaffModal v-if="staffShow" :id="staffId" :show.sync="staffShow" @success="modalSuccess" :title="staffTitle" :isGeneric="isGeneric"/>
    <RecommendationSettingsModal :userId="userId" :show.sync="recommendationSettingsShow" @success="recommendationSettingsSuccess" />

    <b-modal :title="duplicateTitle"
        v-model="duplicateShow"
        @hidden="duplicateCancel"
        content-class="shadow"
        no-close-on-backdrop
        >

      <template v-if="isGeneric">
        <b-form-group :label="$t('staff.field.name')" label-for="duplicateName">
          <b-input-group>
            <b-form-input id="duplicateName" type="text"
              :data-vv-as="$t('staff.field.name')"
              data-vv-name="duplicateName"
              data-vv-delay="500"
              v-model="duplicateName"/>
          </b-input-group>
          <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateNameError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicateName') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      <template v-else>
        <b-form-group :label="$t('staff.field.email')" label-for="duplicateEmail">
          <b-input-group>
            <b-form-input id="duplicateEmail" type="text"
              :data-vv-as="$t('staff.field.email')"
              data-vv-name="duplicateEmail"
              data-vv-delay="500"
              v-model="duplicateEmail"/>
          </b-input-group>
          <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateEmailError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicateEmail') }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group :label="$t('staff.field.firstName')" label-for="duplicateFirstname">
          <b-input-group>
            <b-form-input id="duplicateFirstname" type="text"
              v-model="duplicateFirstname"/>
          </b-input-group>
        </b-form-group>

        <b-form-group :label="$t('staff.field.lastName')" label-for="duplicatLastname">
          <b-input-group>
            <b-form-input id="duplicatLastname" type="text"
              :data-vv-as="$t('staff.field.lastName')"
              data-vv-name="duplicateLastname"
              data-vv-delay="500"
              v-model="duplicateLastname"/>
          </b-input-group>
          <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateLastnameError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicateLastname') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </template>

      <template v-slot:modal-footer="{ cancel }">
          <b-button v-if="duplicateInProgress" disabled size="sm" variant="success"><b-spinner small type="grow" />{{ $t('button.processing') }}</b-button>
          <b-button v-else size="sm" variant="success" @click="duplicateOk">{{ $t('button.duplicate') }}</b-button>
          <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('staff.confirmation.title_delete')"
        v-model="confirmDeleteShow"
        @ok="confirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        <template v-if="selectedWithUser.length > 0">
          <template v-if="selectedWithUser.length > 1">
            <p>{{ $t('staff.confirmation.delete_plural_user_link_warning_line1') }}</p>
            <ul>
              <template v-for="(item, index) in selectedWithUser">
                <li :key="index">{{item.userEmail[0] }}</li>
              </template>
            </ul>
            <p>{{ $t('staff.confirmation.delete_plural_user_link_warning_line2') }}</p>
          </template>
          <template v-else>
            <p>{{ $t('staff.confirmation.delete_user_link_warning_line1', [selectedWithUser[0].userEmail[0]]) }}</p>
            <p>{{ $t('staff.confirmation.delete_user_link_warning_line2') }}</p>
          </template>
        </template>
        <p>{{ $t(selected.length > 1? 'staff.confirmation.delete_plural':'staff.confirmation.delete') }}</p>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <!--Gantt Import Dialog -->
    <GanttImportDialog :properties="[{ value: 'color', text: $t('field.color') }, { value: 'company', text: $t('staff.field.company') }, { value: 'department', text: $t('staff.field.department') }, { value: 'email', text: $t('staff.field.email') }, { value: 'enddate', text: $t('staff.field.endDate') }, { value: 'firstname', text: $t('staff.field.firstName') }, { value: 'identifier', text: $t('field.identifier') }, { value: 'name', text: $t('staff.field.lastName') }, { value: 'location', text: $t('staff.field.location') }, { value: 'notes', text: $t('staff.field.notes') }, { value: 'payamount', text: $t('staff.field.payAmount') }, { value: 'paycurrency', text: $t('staff.field.payCurrency') }, { value: 'payfrequency', text: $t('staff.field.payFrequency') }, { value: 'phone', text: $t('staff.field.phones') }, { value: 'position', text: $t('staff.field.position') }, { value: 'resources', text: $t('staff.field.resources') }, { value: 'skills', text: $t('staff.field.skills') }, { value: 'socials', text: $t('staff.field.socials') }, { value: 'startdate', text: $t('staff.field.startDate') }, { value: 'tag', text: $t('field.tag') }, { value: 'type', text: $t('staff.field.type') }, { value: 'websites', text: $t('staff.field.websites') }]" :mode="'STAFF'" :show="docImportShow"
      :title="$t('staff.button.import_document')"
      @modal-ok="docImportOk"
      @modal-cancel="docImportCancel" />
    
    <GanttImportDialog :properties="[{ value: 'color', text: $t('field.color') }, { value: 'department', text: $t('staff.field.department') }, { value: 'identifier', text: $t('field.identifier') }, { value: 'location', text: $t('staff.field.location') }, { value: 'name', text: $t('staff.field.name') }, { value: 'payamount', text: $t('staff.field.payAmount') }, { value: 'paycurrency', text: $t('staff.field.payCurrency') }, { value: 'payfrequency', text: $t('staff.field.payFrequency') }, { value: 'skills', text: $t('staff.field.skills') }, { value: 'type', text: $t('staff.field.type') }, { value: 'resources', text: $t('staff.field.resources') }, { value: 'tag', text: $t('field.tag') }]" :mode="'STAFF_GENERIC'" :show="docImportGenericShow"
      :title="$t('staff.button.import_document')"
      @modal-ok="docImportGenericOk"
      @modal-cancel="docImportGenericCancel" />
    
    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable"/>
  </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import alertStateEnum from '@/enums/alert-state';
import { filterOutViewDenyProperties, columnDefSortFunc } from '@/views/management/script/common';
import { getPermissionDenyProperties } from '@/helpers/permission';
import PayFrequencyRenderer from '@/components/Aggrid/CellRenderer/PayFrequency';
import { compositeService, staffService, recommendationProfileService } from '@/services';
import { viewProfileService, tagService
       } from '@/services';

import { getKeysWithoutRedactedFields } from '@/services/common';
import { strRandom, extractRowsFromData, DepartmentBeforeMount, pruneTree, invertColor, getFirstColor, makeTree, prepareDepartmentTreeRequest } from '@/helpers';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import CostCellRenderer from '@/components/Aggrid/CellRenderer/Cost';
import StaffTypeCellRenderer from '@/components/Aggrid/CellRenderer/StaffType';
import TaskResourceCellRenderer from '@/components/Aggrid/CellRenderer/TaskResource';
import ArrayCellRenderer from '@/components/Aggrid/CellRenderer/Array';
import DateTimeCellRenderer from '@/components/Aggrid/CellRenderer/DateTime';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import PriorityNavigation from '@/components/PriorityNavigation/PriorityNavigation';
import ColorCellRenderer from '@/components/Aggrid/CellRenderer/Color';
import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows';


function getColumnDefs(c) {
  return {
    colId: c.colId
    , width: c.actualWidth
    , sort: c.sort != null? c.sort : null
    , sortIndex: c.sortIndex != null? c.sortIndex : null
  }
}

function ServerSideDatasource(self) {
  return {
    getRows(params) {
      if (self.lackOfMandatoryField()) {
        params.successCallback([], 0);
        self.showNoRowsOverlay(self, self.$t('entity_selector.error.insufficient_permission_to_show_data'))
        return;
      }
      staffService.list(self.buildParams(params)).then((response) => {
        const gridApi = self.gridOptions.api;
        self.totalRecords = response.arg_total;
           
        for (const stf of response.data) {
          // save the staff uuIds for filtering the org chart
          self.allStaff[stf.uuId] = true;
        }
        self.staffDataLoaded = true;// org chart must wait for the staff list
        if (self.staffDataLoaded &&
            self.genericDataLoaded &&
            !self.orgDataLoaded) {
            self.orgDataLoaded = true;
          self.reloadOrgChart();
        }
        
        params.successCallback(response.data, response.arg_total);
        if(gridApi != null && self.modalShow) {
          setTimeout(() => {
            gridApi.deselectAll();
            if(self.selected && self.selected.length > 0) {
              const selected = cloneDeep(self.selected);
              gridApi.forEachNode(function(node) {
                if (node.data != null && selected.includes(node.data.uuId)) {
                  node.setSelected(true);
                  selected.splice(selected.indexOf(node.data.uuId), 1);
                }
              });
              
              if (selected.length !== 0 && !self.bookings) {
                self.alertMsg = self.$t('staff.error.not_found');
                self.alertState = alertStateEnum.WARNING;
              }
            }
          }, 300)
        }
        if (self.totalRecords !== 0 &&
            gridApi != null) {
          gridApi.hideOverlay();
        }       
        if (response.arg_total === 0) {
          gridApi.showNoRowsOverlay();
        }
        
      })
      .catch(e => {
        // console.error(error); // eslint-disable-line no-console
        params.successCallback([], 0)
        if (e != null && e.response != null && e.response.status == 403) {
          self.showNoRowsOverlay(self, self.$t('entity_selector.error.insufficient_permission_to_show_data'))
        }
        // params.successCallback([], self.totalRecords);
        // if (params.request.api != null) {
        //   params.request.api.showNoRowsOverlay();
        // }
      });
    }
  }
}

function RecommendedDatasource(parent, self) {
  return {
    getRows(params) {
      if (parent.lackOfMandatoryField()) {
        parent.showNoRowsOverlay(self, parent.$t('entity_selector.error.insufficient_permission_to_show_data'))
        params.successCallback([], 0);
        return;
      }
      staffService.staffToTask([{ uuId: parent.taskUuId}], parent.staffUuIds, self.settings)
      .then((response) => {
        let data = response.data[response.data.jobCase];
        data = data && data.length > 0? data[0] : [];
        if(data.length > 0) { data = data[0]; }
        data = data.staffAssignmentList || [];

        for (var i = data.length - 1; i >= 0; i--) {
          data[i].duration = `${data[i].duration / 480}D`;
          if (typeof data[i].staffSkillList !== 'undefined') {
            for (var j = 0; j < data[i].staffSkillList.length; j++) {
              if (typeof data[i].skills === 'undefined') {
                data[i].skills = '';
              }
              
              var skills = `${data[i].staffSkillList[j].name} (${data[i].staffSkillList[j].level})`;
              if (data[i].skills.indexOf(skills) === -1) {
                if (data[i].skills !== '') {
                  data[i].skills += ', ';
                }
                data[i].skills += skills;
              }
            }
          }

          if (self.searchFilter !== '') { 
            if (((typeof data[i].staffSkillList !== 'undefined' && typeof data[i].staffSkillList[0].name !== 'undefined' && !data[i].staffSkillList[0].name.toLowerCase().includes(self.searchFilter.toLowerCase())) ||
                 typeof data[i].staffSkillList === 'undefined') &&
                 (typeof data[i].firstName !== 'undefined' && !data[i].firstName.toLowerCase().includes(self.searchFilter.toLowerCase()) && 
                  typeof data[i].lastName !== 'undefined' && !data[i].lastName.toLowerCase().includes(self.searchFilter.toLowerCase()))) {
              data.splice(i, 1);
            }
          }
        }

        params.successCallback(data, data.length);
        if (self.gridOptions && self.gridOptions.api) {
          self.gridOptions.api.hideOverlay();
        }     
        if (response.arg_total === 0) {
          self.gridOptions.api.showNoRowsOverlay();
        }
      })
      .catch(e => {
        // console.error(error); // eslint-disable-line no-console
        // params.successCallback([], 0);
        // if (self.gridOptions && self.gridOptions.api) {
        //   self.gridOptions.api.showNoRowsOverlay();
        // }
        params.successCallback([], 0)
        if (e != null && e.response != null && e.response.status == 403) {
          parent.showNoRowsOverlay(self, parent.$t('entity_selector.error.insufficient_permission_to_show_data'))
        }
      });
    }
  }
}

function GenericDatasource(self, modalSelf) {
  const buildParams = ({ request: {sortModel, endRow, startRow} }) => {
    const params = {
      start: startRow,
      limit: endRow - startRow + 1,
    };
    params.ksort = []
    params.order = []
    for(let i = 0, len = sortModel.length; i < len; i++) {
      if (sortModel[i].colId === 'uuId') {
        params.ksort.push('name'); //User firstname for name till backend corrects the name property.
      } else if (sortModel[i].colId === 'skills') {
        params.ksort.push('skillName');
      } else {
        params.ksort.push(sortModel[i].colId);
      }
      params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr');
    }
    
    let bookingrule = null;
    const companyrule = modalSelf.companyrule;
    const departmentrule = modalSelf.departmentrule;
    const skillrule = modalSelf.skillrule;
    const locationrule = modalSelf.locationrule;
    const resourcerule = modalSelf.resourcerule;
    
    if (modalSelf.projectIds.length > 0 && modalSelf.bookings) {
      bookingrule = ['STAFF.BOOKING.PROJECT.uuId', 'within', modalSelf.projectIds.join('|')];  
      if (self.searchFilter !== '') {
        params.filter = [
          '_or_', [
            ['STAFF.firstName', 'has', self.searchFilter]
            , ['STAFF.lastName', 'has', self.searchFilter]
            , ['STAFF.identifier', 'has', self.searchFilter]
            , ['STAFF.COMPANY.name', 'has', self.searchFilter]
            , ['STAFF.DEPARTMENT.name', 'has', self.searchFilter]
            , ['STAFF.email', 'has', self.searchFilter]
            , ['STAFF.position', 'has', self.searchFilter]
            , ['STAFF.LOCATION.name', 'has', self.searchFilter]
            , ['STAFF.TAG.name', 'has', self.searchFilter]
            , ['STAFF.SKILL.name', 'has', self.searchFilter]
          ],
          bookingrule
        
        ]; 
      }
      else {
        params.filter = [bookingrule];
      }
    }
    else {
      params.filter = self.searchFilter;
    }
        
    if (departmentrule) {
      if (params.filter === "") {
        params.filter = [];
      }
      params.filter.push(departmentrule);
    }
    if (companyrule) {
      if (params.filter === "") {
        params.filter = [];
      }
      params.filter.push(companyrule);
    }
    if (skillrule) {
      if (params.filter === "") {
        params.filter = [];
      }
      params.filter.push(skillrule);
    }
    if (locationrule) {
      if (params.filter === "") {
        params.filter = [];
      }
      params.filter.push(locationrule);
    }
    if (resourcerule) {
      if (params.filter === "") {
        params.filter = [];
      }
      params.filter.push(resourcerule);
    }
    
    if (modalSelf.staffListUuIds) {
      params.holders = modalSelf.staffListUuIds;
    }
    
    return params;
  }
  return {
    getRows(params) {
      if (modalSelf.lackOfMandatoryField()) {
        modalSelf.showNoRowsOverlay(self, modalSelf.$t('entity_selector.error.insufficient_permission_to_show_data'))
        params.successCallback([], 0);
        return;
      }
      staffService.list(buildParams(params), true).then((response) => {
        self.totalRecords = response.arg_total;
        
        for (const stf of response.data) {
          // save the staff uuIds for filtering the org chart
          modalSelf.allStaff[stf.uuId] = true;
        }
        modalSelf.genericDataLoaded = true;// org chart must wait for the generic list
        if (modalSelf.staffDataLoaded &&
            modalSelf.genericDataLoaded &&
            !modalSelf.orgDataLoaded) {
            modalSelf.orgDataLoaded = true;
          modalSelf.reloadOrgChart();
        }
        
        
        params.successCallback(response.data, response.arg_total);
        if(self.gridOptions.api) {
          self.gridOptions.api.deselectAll();
          if(self.selected && self.selected.length > 0) {
            const selected = cloneDeep(self.selected);
            self.gridOptions.api.forEachNode(function(node) {
              if (node.data && selected.includes(node.data.uuId)) {
                node.setSelected(true);
                selected.splice(selected.indexOf(node.data.uuId), 1);
              }
            });
          }
        }
        self.gridOptions.api.hideOverlay();
             
        if (response.arg_total === 0) {
          self.gridOptions.api.showNoRowsOverlay();
        }
      })
      .catch(e => {
        // console.error(error); // eslint-disable-line no-console
        // params.successCallback([], self.totalRecords);
        // self.gridOptions.api.showNoRowsOverlay();
        params.successCallback([], 0)
        if (e != null && e.response != null && e.response.status == 403) {
          modalSelf.showNoRowsOverlay(self, modalSelf.$t('entity_selector.error.insufficient_permission_to_show_data'))
        }
      });
    }
  }
}

export default {
  name: 'StaffSelectorModal',
  components: {
    'ag-grid-vue': AgGridVue,
    StaffModal: () => import('@/components/modal/StaffModal'),
    ListFilter: () => import('@/components/ListFilter/ListFilter'),
    GanttImportDialog: () => import('@/components/Gantt/components/GanttImportDialog'),
    RecommendationSettingsModal: () => import('@/components/modal/RecommendationSettingsModal'),
    AlertFeedback: () => import('@/components/AlertFeedback'),
    InProgressModal: () => import('@/components/modal/InProgressModal'),
    PriorityNavigation,

    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    'detailLinkCellRenderer': DetailLinkCellRenderer,
    'costCellRenderer': CostCellRenderer,
    'payFrequencyRenderer': PayFrequencyRenderer,
    'staffTypeCellRenderer': StaffTypeCellRenderer,
    'taskResourceCellRenderer': TaskResourceCellRenderer,
    'arrayCellRenderer': ArrayCellRenderer,
    'dateTimeCellRenderer': DateTimeCellRenderer,
    'colorCellRenderer': ColorCellRenderer,
    //Overlay
    noRowsOverlay: NoRowsOverlay
    /* eslint-enable vue/no-unused-components */
    
  },
  props: {
    idPrefix: {
      type: String,
      default: null
    },
    show: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    title: {
      type: String,
      default: null
    },
    taskUuId: {
      type: String,
      default: null
    },
    enablePagination: {
      type: Boolean,
      default: true
    },
    selectedStaff: {
      type: Array,
      default: () => { return []; }
    },
    allowNone: {
      type: Boolean,
      default: false
    },
    companies: {
      type: Array,
      default: () => { return []; }
    },
    departments: {
      type: Array,
      default: () => { return []; }
    },
    skill: {
      type: Object,
      default: null
    },
    location: {
      type: Object,
      default: null
    },
    resource: {
      type: Object,
      default: null
    },
    toggledByAutoAssignStaff: {
      type: Boolean,
      default: false
    },
    projectIds: {
      type: Array,
      default: () => []
    },
    hideBookings: {
      type: Boolean,
      default: false
    },
    staffListUuIds: {
      type: Array,
      default: null
    }
  },
  data: function() {
    return {
      userId: null,
      modelInfo: null,
      id: `${this.idPrefix}_STAFF_LIST`,
      permissionName: "STAFF",
      inProgressShow: false,
      inProgressLabel: null,
      inProgressStoppable: false,
      inProgressState: {
        cancel: false
      },
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      groupColumnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,
  
      modalShow: false,
      disableEdit: true,
      disableDelete: true,
      disableDuplicate: true,
      disableOk: true,
      selected: [],

      staffId: null,
      staffShow: false,
      isGeneric: false,
      alertMsg: null,
      alertMsgDetails: { title: null, list: [] },
      alertState: alertStateEnum.SUCCESS,
      // alertPositive: false,

      toDeleteIds: [],
      confirmDeleteShow: false,
      totalRecords: 0,
      
      searchFilter: '',
      searchFilterTerm: '',
      activeTab: 0,
      
      staffUuIds: [],
      recommendationSettingsShow: false,
      activeStaff: true,
      inactiveStaff: false,
      orgChartActiveStaff: true,
      orgChartInactiveStaff: false,
      orgChartRealStaff: true,
      orgChartGenericStaff: false,
      
      recommended: {
        gridOptions: null,
        gridApi: null,
        columnApi: null,
        columnDefs: null,
        groupColumnDefs: null,
        autoGroupColumnDef: null,
        searchFilter: '',
        searchFilterTerm: '',
        selected: [],
        settings: {
          allow_over_alloc: false,
          match_staff_based_on_skills: true,
          include_staff_exact_skill: true,
          include_staff_higher_skill: true,
          adjust_task_duration_higher: false,
          include_staff_lower_skill: true,
          adjust_task_duration_lower: false
        },
        noRowsMessage: null,
        noRowsOverlayComponentParams: null,
        lastOpenColumnMenuParams: null
      },

      // tell the pruneTree function to match based on staff name
      matchStaff: true,
      orgChart: {
        gridOptions: null,
        gridApi: null,
        columnApi: null,
        columnDefs: null,
        groupColumnDefs: null,
        autoGroupColumnDef: null,
        searchFilter: '',
        searchFilterTerm: '',
        selected: [],
        listStaff: true,
        preselectedStaff: {},
        rowData: null,
        disableEdit: true,
        disableDelete: true,
        disableDuplicate: true,
        noRowsMessage: null,
        noRowsOverlayComponentParams: null,
        lastOpenColumnMenuParams: null
      },

      generic: {
        gridOptions: null,
        gridApi: null,
        columnApi: null,
        columnDefs: null,
        searchFilter: '',
        searchFilterTerm: '',
        selected: [],
        totalRecords: 0,
        disableEdit: true,
        disableDelete: true,
        disableDuplicate: true,
        select_state: {
          checked: false,
          indeterminate: false
        },
        coloring: {
          none: true,
          staff: false,     
          department: false,
          location: false,      
          skill: false,
          resource: false
        },
        noRowsMessage: null,
        noRowsOverlayComponentParams: null,
        lastOpenColumnMenuParams: null
      },

      select_state: {
        checked: false,
        indeterminate: false
      },
      duplicateRefId: null,
      duplicateShow: false,
      duplicateEmail: null,
      duplicateFirstname: null,
      duplicateLastname: null,
      duplicateName: null,
      duplicateInProgress: false,
      
      settings: {},
      
      docImportShow: false,
      docImportGenericShow: false,

      listToolbarLoaded: false,
      orgChartToolbarLoaded: false,
      
      coloring: {
        none: true,
        staff: false,        
        company: false,
        department: false,
        location: false,      
        skill: false,
        resource: false
      },
      bookings: false,

      noRowsMessage: null,
      noRowsOverlayComponentParams: null,
      lastOpenColumnMenuParams: null,
      
      // a list for filtering the org chart
      allStaff: {},
      staffDataLoaded: false,
      genericDataLoaded: false,
      orgDataLoaded: false
    };
  },
  beforeMount() {
    this.userId = this.$store.state.authentication.user.uuId;
    const orgChartProfileKey = 'staff_selector_orgchart';

    DepartmentBeforeMount(this, this.orgChart, this.$t('staff.department_and_staff'));
    this.orgChart.autoGroupColumnDef.cellRendererParams.checkbox = params => {
          return typeof params.data !== 'undefined' && typeof params.data.staff !== 'undefined';
        };
    this.orgChart.defaultColDef.minWidth = 200;
    // this.orgChart.columnDefs = [
    const colDefs = [
      {
        headerName: this.$t('staff.field.position'),
        field: 'position',
        hide: false,
        minWidth: 150
      },
      {
        headerName: this.$t('field.identifier_full'),
        field: 'identifier',
        minWidth: 100,
        hide: true
      },
      {
        field: 'staff',
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true
      },
      {
        field: 'department',
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true
      },
      {
        field: 'path',
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true
      },
      {
        headerName: this.$t('staff.field.status'),
        field: 'status',
        minWidth: 100,
        hide: true
      },
    ];
    const linkedEntities = [
      { selector: 'STAFF.DEPARTMENT', field: 'department', properties: ['DEPARTMENT'] }
    ]
    this.$store.dispatch('data/schemaAPI', {type: 'api', opts: 'brief' })
    .finally(() => {
      filterOutViewDenyProperties(colDefs, 'STAFF', linkedEntities);
      colDefs.sort(columnDefSortFunc);
      this.orgChart.columnDefs = colDefs;
    });

    this.orgChart.gridOptions.onColumnVisible = (params) => {
      let fromToolPanel = params.source == "toolPanelUi"
      if (fromToolPanel) {
        let colKey = params.column.colId;
        let columnMenuColumnIndex = params.columnApi
          .getAllGridColumns()
          .findIndex(col => {
            return col === this.orgChart.lastOpenColumnMenuParams.column;
          });

        params.columnApi.moveColumn(colKey, columnMenuColumnIndex + 1);
      }
      const cols = params.columnApi.getAllGridColumns().map(i => { 
        return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
      const columnState =  params.columnApi.getColumnState();
      //get the actual hide value from columnState
      for (const col of columnState) {
        const found = cols.find(i => i.colId == col.colId)
        if (found) {
          found.hide = col.hide;
        }
      }
      cols.sort(columnDefSortFunc)
      for (const [index,c] of cols.entries()) {
        params.columnApi.moveColumn(c.colId, index + 1);
      }

      let columns = params.columnApi.getAllDisplayedColumns();
      this.settings[orgChartProfileKey] = columns.map(c => getColumnDefs(c));
      this.updateViewProfile();
    }
    this.orgChart.gridOptions.postProcessPopup = params => {
      if ((params.type == 'columnMenu')) {
        this.orgChart.lastOpenColumnMenuParams = params;
      }
    }

    this.orgChart.gridOptions.onSortChanged = (params) => {
      let columns = params.columnApi.getAllDisplayedColumns();
      this.settings[orgChartProfileKey] = columns.map(c => getColumnDefs(c));
      this.updateViewProfile();
    },
    this.orgChart.gridOptions.onDragStopped = (params) => {
      let columns = params.columnApi.getAllDisplayedColumns();
      this.settings[orgChartProfileKey] = columns.map(c => getColumnDefs(c));
      this.updateViewProfile();
    }
    this.orgChart.gridOptions.onFirstDataRendered = (event) => {
      this.setDefaultColumnWidthForOrgChart(event.api, event.columnApi, this.activeTab);
    }
    this.prepareGrid('list');
    this.prepareGrid('recommended');
    this.prepareGrid('generic');
  },
  mounted() {
    this.$nextTick(() => {
      this.prepareData()
    })
  },
  created() {
    this.getModelInfo();
    this.updateModalShow(this.show);
    this.selected = cloneDeep(this.selectedStaff);
    this.recommended_settings_template = JSON.parse(JSON.stringify(this.recommended.settings));

    this.queryMandatoryFields = ['uuId', 'firstName', 'lastName', 'genericStaff', 'startDate', 'endDate'];
    this.queryMandatoryFields_orgChart = ['uuId', 'firstName', 'lastName', 'genericStaff', 'startDate', 'endDate', 'DEPARTMENT.uuId'];
    this.mandatoryFields = ['email', 'lastName', 'locations', 'staffType'];
    this.mandatoryFieldsForGeneric = ['firstName', 'locations', 'staffType'];

    this.orgChart.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage(this.orgChart)
    }

    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage(this)
    }

    this.generic.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage(this.generic)
    }

    this.recommended.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage(this.generic)
    }
  },
  beforeDestroy() {
    this.list_newToProfile = null;
    this.recommended_newToProfile = null;
    this.generic_newToProfile = null;
    this.orgchart_newToProfile = null;
    this.recommended_settings_template = null;
  },
  watch: {
    async show(newValue) {
      if(newValue) {
        this.resetAlert();
        this.searchFilter = '';
        this.orgChart.searchFilter = '';
        this.recommended.searchFilter = '';
        this.generic.searchFilter = '';
        await this.prepareData();
      } else {
        this.treeData = [];
        this.gridColumnApi = null;
        this.recommended.gridColumnApi = null;
        this.orgChart.gridColumnApi = null;
        this.generic.gridColumnApi = null;
        this.activeTab = 0;
        this.listToolbarLoaded = false;
        this.orgChartToolbarLoaded = false;
      }
      this.updateModalShow(newValue);
    },
    activeTab(newValue) {
      this.setDefaultColumnWidthForOrgChart(this.orgChart.gridOptions.api, this.orgChart.gridOptions.columnApi, newValue);
    }
  },
  computed: {
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('staff.grid.loading_list') }</span>`;
    },
    tabList() {
      const l = [{ name: 'list' }];
      if(this.taskUuId != null) {
        l.push({ name: 'recommended'});
      }
      l.push({ name: 'orgChart' });
      l.push({ name: 'generic' });
      return l;
    },
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    staffTitle() {
      let labelNew = null;
      let labelEdit = null;
      if(this.isGeneric) {
        labelNew = this.$t('staff.title_generic_new');
        labelEdit = this.$t('staff.title_generic_detail');
      } else {
        labelNew = this.$t('staff.title_new');
        labelEdit = this.$t('staff.title_detail');
      }
      return this.staffId && this.staffId.indexOf('STAFF_NEW') == -1? labelEdit : labelNew;
    },
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('staff.grid.no_data') }</span>`;
    },
    labelTitle() {
      return this.title? this.title : this.$t('staff.title_selector');
    },
    selectedWithUser() {
      if(this.gridOptions.api) {
        let combined = new Set();
        this.selected.forEach(i => combined.add(i));
        this.recommended.selected.forEach(i => combined.add(i));
        this.orgChart.selected.forEach(i => combined.add(i.uuId));
        combined = Array.from(combined);
        const nodes = [];
        for(const id of combined) {
          let value;
          if (this.gridOptions.api) {
            this.gridOptions.api.getRowNode(id);
            if(value) {
              nodes.push(value);
              continue;
            }
          }
          
          if(this.recommended && this.recommended.gridOptions.api) {
            value = this.recommended.gridOptions.api.getRowNode(id);
            if(value) {
              nodes.push(value);
              continue;
            }
          }
          if(this.orgChart && this.orgChart.gridOptions.api) {
            value = this.orgChart.gridOptions.api.getRowNode(id);
            if(value) {
              nodes.push(value);
              continue;
            }
          }
        }
        return nodes.filter(i => i.data && i.data.userEmail && i.data.userEmail.length > 0).map(j => j.data);
      }
      return [];
    },
    showDuplicateEmailError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicateEmail');
    },
    showDuplicateLastnameError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicateLastname');
    },
    showDuplicateNameError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicateName');
    },
    duplicateTitle() {
      return this.$t('staff.title_duplicate');
    },
    listSelectedCount() {
      return this.selected? this.selected.length : 0;
    },
    recommendedSelectedCount() {
      return this.recommended? this.recommended.selected.length : 0;
    },
    genericSelectedCount() {
      return this.generic? this.generic.selected.length : 0;
    },
    orgChartSelectedCount() {
      return this.orgChart? this.orgChart.selected.length : 0; 
    },
    companyrule() {
      if (this.companies.length !== 0 || (this.$store.state.company && this.$store.state.company.type !== 'Primary' &&
          this.$store.state.company.filterIds)) {
        const companies = this.companies.length > 0 ? this.companies.map(c => { return c.uuId }) : this.$store.state.company.filterIds;
        const companyrule = ['STAFF.COMPANY.uuId', 'within', companies.join('|')];
        return companyrule
      }
      return null;
    },
    departmentrule() {
      if (this.departments.length !== 0) {
        const departments = this.departments.map(d => { return d.uuId });
        const departmentrule = ['STAFF.DEPARTMENT.uuId', 'within', departments.join('|')];
        return departmentrule
      }
      return null;
    },
    skillrule() {
      if (this.skill) {
        const skill = this.skill.uuId;
        const skillrule = ['STAFF.SKILL.uuId', 'eq', skill];
        return skillrule
      }
      return null;
    },
    locationrule() {
      if (this.location) {
        const location = this.location.uuId;
        const locationrule = ['STAFF.LOCATION.uuId', 'eq', location];
        return locationrule
      }
      return null;
    },
    resourcerule() {
      if (this.resource) {
        const resource = this.resource.uuId;
        const resourcerule = ['STAFF.RESOURCE.uuId', 'eq', resource];
        return resourcerule
      }
      return null;
    },
    payFrequencyOptions() {
      let options = null;
      if (this.modelInfo != null) {
        const rawOptions = this.modelInfo.find(f => f.field === 'payFrequency').options;
        if (rawOptions != null) {
          options = rawOptions.map(i => {
            return { value: i, text: this.$t(`payFrequency.${i}`) }
          });
        }
      }
      if (options != null) {
        return options;
      }
      return [];
    },
    colorMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    }
  },
  methods: {
    setDefaultColumnWidthForOrgChart(gridApi, columnApi, activeTab) {
      if (this.tabList[activeTab].name == 'orgChart' && this.orgchart_newToProfile != null && this.orgchart_newToProfile == true) {
        gridApi.sizeColumnsToFit();
        this.$nextTick(() => {
          const columns = columnApi.getAllDisplayedColumns();
          this.settings['staff_selector_orgchart'] = columns.map(c => getColumnDefs(c));
          this.updateViewProfile();
        })
      }
    },
    async prepareData() {
      this.selected = cloneDeep(this.selectedStaff);
      if (this.taskUuId !== null && (this.selectedStaff == null || this.selectedStaff.length == 0)) {
        this.activeTab = 1;
      } 

      //Apply $nextTick() to wait for $refs to get ready before referencing it
      this.$nextTick(() => {
        this.updateTabLink(this.activeTab);
      });

      await this.loadViewProfile()
    },
    getModelInfo() {
      const self = this;
      this.$store.dispatch('data/info', {type: "api", object: "STAFF"}).then(value => {
        self.modelInfo = value.STAFF.properties;
      })
      .catch(e => {
        this.httpAjaxError(e);
      });
    },
    processNodes(orgSelectedStaffIds) {
      if (this.orgChart.gridOptions.api !== null) {
        this.orgChart.gridOptions.api.forEachNode((node /**, b */) => {
          if (orgSelectedStaffIds.includes(node.key)) {
            node.setSelected(true);
          }
          if (typeof node.data !== 'undefined' &&
              typeof node.data.type !== 'undefined' &&
              (this.companies.length === 0 &&
              node.data.uuId !== this.$store.state.company.uuId)) {
            node.setExpanded(false);
          }
        });
      }
    },
    checkDisableButtons(self=null) {
      const _this = self || this;
      
      _this.disableEdit = _this.disableDuplicate = _this.selected.length != 1;
      _this.disableDelete = _this.selected.length < 1;

      const totalSelectionNumber = this.selected.length + this.recommended.selected.length + this.orgChart.selected.length + this.generic.selected.length;
      this.disableOk = (this.multiple? (this.selected.length < 1 && this.recommended.selected.length < 1 && this.orgChart.selected.length < 1 && this.generic.selected.length < 1) : (totalSelectionNumber != 1));
    },
    prepareGrid(gridType) {
      const profileKey = `staff_selector_${gridType}`;
      const self = this;
      let gridTarget = null;
      if (gridType == 'generic') {
        gridTarget = this.generic;
      } else if (gridType == 'recommended') {
        gridTarget = this.recommended;
      } else { // 'list'
        gridTarget = this;
      }
      
      const gridOptions = (uuIdFieldName, data) => 
        {
          return {
            onSelectionChanged: function(event) {
              data.selected.splice(0, data.selected.length, ...(event.api.getSelectedNodes().map(i => i.data[uuIdFieldName])));
              self.checkDisableButtons(data);
              self.updateSelectState(event.api, data);
            },
            onColumnVisible: function(params) {
              let fromToolPanel = params.source == "toolPanelUi"
              if (fromToolPanel) {
                let colKey = params.column.colId;
                let columnMenuColumnIndex = params.columnApi
                  .getAllGridColumns()
                  .findIndex(col => {
                    return col === gridTarget.lastOpenColumnMenuParams.column;
                  });

                params.columnApi.moveColumn(colKey, columnMenuColumnIndex + 1);
              }
              const cols = params.columnApi.getAllGridColumns().map(i => { 
                return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
              const columnState =  params.columnApi.getColumnState();
              //get the actual hide value from columnState
              for (const col of columnState) {
                const found = cols.find(i => i.colId == col.colId)
                if (found) {
                  found.hide = col.hide;
                }
              }
              cols.sort(columnDefSortFunc)
              for (const [index,c] of cols.entries()) {
                params.columnApi.moveColumn(c.colId, index);
              }
              let columns = params.columnApi.getAllColumns();
              self.settings[profileKey] = columns.map(c => getColumnDefs(c));
              self.updateViewProfile();
            },
            postProcessPopup: params => {
              if ((params.type == 'columnMenu')) {
                gridTarget.lastOpenColumnMenuParams = params;
              }
            },
            onSortChanged: function(params) {
              let columns = params.columnApi.getAllDisplayedColumns();
              self.settings[profileKey] = columns.map(c => getColumnDefs(c));
              self.updateViewProfile();
            },
            onDragStopped: function(params) {
              let columns = params.columnApi.getAllDisplayedColumns();
              self.settings[profileKey] = columns.map(c => getColumnDefs(c));
              self.updateViewProfile();
            },
            onFirstDataRendered: (event) => {
              const newToProfileKey = `${gridType}_newToProfile`;
              if (self[newToProfileKey] != null && self[newToProfileKey] == true) {
                self[newToProfileKey] = null;
                event.api.sizeColumnsToFit();
                self.$nextTick(() => {
                  const columns = event.columnApi.getAllDisplayedColumns();
                  self.settings[profileKey] = columns.map(c => getColumnDefs(c));
                  self.updateViewProfile();
                })
              }
            },
            getRowId : function(params) {
              return params.data[uuIdFieldName];
            }
          }
        };

      const defaultColDef = {
        sortable: true,
        resizable: true,
        minWidth: 240,
        lockPinned: true,
        hide: true,
        menuTabs: ['columnsMenuTab']
      };

      const context = {
        componentParent: self,
        coloring: gridTarget.coloring
      };

      const lastNameCol = (uuIdFieldName) => {
        return {
          headerName: this.$t('staff.field.lastName'),
          field: uuIdFieldName,
          cellRenderer: 'detailLinkCellRenderer',
          cellRendererParams: {
            tabName: gridType
          },
          checkboxSelection: true,
          lockVisible: true,
          pinned: 'left',
          hide: false,
          minWidth: 150,
          // flex: 1,
          sort: 'asc',
          cellStyle: params => {
            if (gridType === 'list' ||
                gridType === 'generic') {
              const coloring = gridType === 'list' ? self.coloring : self.generic.coloring;
              // const coloring = target.coloring;
              if (params.data &&
                  params.data.color &&
                  coloring.staff) {
                  return { background: params.node.data.color, color: invertColor(params.node.data.color, true) };
              }
              else if (params.data &&
                  params.data.companyColor &&
                  coloring.company) {
                  const color = getFirstColor(params.node.data.companyColor);
                  if (color) return { background: color, color: invertColor(color, true) };
              }
              else if (params.data &&
                  params.data.departmentColor &&
                  params.data.departmentColor.length > 0 &&
                  params.data.departmentColor[0] !== '' &&
                  coloring.department) {
                  const color = getFirstColor(params.node.data.departmentColor);
                  if (color) return { background: color, color: invertColor(color, true) };
              }
              else if (params.data &&
                  params.data.locationColor &&
                  params.data.locationColor.length > 0 &&
                  params.data.locationColor[0] !== '' &&
                  coloring.location) {
                  const color = getFirstColor(params.node.data.locationColor);
                  if (color) return { background: color, color: invertColor(color, true) };
              }
              else if (params.data &&
                  params.data.skillColor &&
                  params.data.skillColor.length > 0 &&
                  params.data.skillColor[0] !== '' &&
                  coloring.skill) {
                  const color = getFirstColor(params.node.data.skillColor);
                  if (color) return { background: color, color: invertColor(color, true) };
              }
              else if (params.data &&
                  params.data.resourceColor &&
                  params.data.resourceColor.length > 0 &&
                  params.data.resourceColor[0] !== '' &&
                  coloring.resource) {
                  const color = getFirstColor(params.node.data.resourceColor);
                  if (color) return { background: color, color: invertColor(color, true) };
              }
            }
          }
        }
      }
      const firstNameCol = {
        headerName: this.$t('staff.field.firstName'),
        field: 'firstName',
        hide: false,
        minWidth: 150
      }

      const nameCol = (uuIdFieldName) => {
        return {
          headerName: this.$t('staff.field.name'),
          field: uuIdFieldName,
          cellRenderer: 'detailLinkCellRenderer',
          checkboxSelection: true,
          cellRendererParams: {
            tabName: gridType
          },
          lockVisible: true,
          pinned: 'left',
          hide: false,
          minWidth: 150,
          // flex: 1,
          sort: 'asc',
          cellStyle: params => {
            const coloring = self.generic.coloring;
            if (params.data &&
                params.data.color &&
                coloring.staff) {
                return { background: params.node.data.color, color: invertColor(params.node.data.color, true) };
            }
            else if (params.data &&
                params.data.companyColor &&
                coloring.company) {
                const color = getFirstColor(params.node.data.companyColor);
                if (color) return { background: color, color: invertColor(color, true) };
            }
            else if (params.data &&
                params.data.departmentColor &&
                params.data.departmentColor.length > 0 &&
                params.data.departmentColor[0] !== '' &&
                coloring.department) {
                const color = getFirstColor(params.node.data.departmentColor);
                if (color) return { background: color, color: invertColor(color, true) };
            }
            else if (params.data &&
                params.data.locationColor &&
                params.data.locationColor.length > 0 &&
                params.data.locationColor[0] !== '' &&
                coloring.location) {
                const color = getFirstColor(params.node.data.locationColor);
                if (color) return { background: color, color: invertColor(color, true) };
            }
            else if (params.data &&
                params.data.skillColor &&
                params.data.skillColor.length > 0 &&
                params.data.skillColor[0] !== '' &&
                coloring.skill) {
                const color = getFirstColor(params.node.data.skillColor);
                if (color) return { background: color, color: invertColor(color, true) };
            }
            else if (params.data &&
                params.data.resourceColor &&
                params.data.resourceColor.length > 0 &&
                params.data.resourceColor[0] !== '' &&
                coloring.resource) {
                const color = getFirstColor(params.node.data.resourceColor);
                if (color) return { background: color, color: invertColor(color, true) };
            }
          }
        }
      } 

      const positionCol = { 
        headerName: this.$t('staff.field.position'),
        field: 'position',
        hide: true,
        minWidth: 150
      }
      
      const locationCol = {
        headerName: this.$t('staff.field.location'),
        field: 'location',
        hide: true,
        minWidth: 150
      }
        
      const departmentCol = {
        headerName: this.$t('staff.field.department'),
        field: 'department',
        hide: true,
        minWidth: 150
      }

      const resourcesCol = {
        headerName: this.$t('staff.field.resources'),
        field: 'resources',
        cellRenderer: 'taskResourceCellRenderer',
        hide: true,
        minWidth: 150
      }

      const skillCol = {
        headerName: this.$t('staff.field.skills'),
        field: 'skills',
        minWidth: 150,
        hide: true
      }

      const payAmountCol = {
        headerName: this.$t('staff.field.payAmount'),
        field: 'payAmount',
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          customCurrencyProp: 'currencyCode'
        },
        minWidth: 100,
        hide: true
      }

      const payFrequencyCol = {
        headerName: this.$t('staff.field.payFrequency'),
        field: 'payFrequency',
        cellRenderer: 'payFrequencyRenderer',
        cellRendererParams: {
          getOptions: () => {
            return self.payFrequencyOptions;
          }
        },
        minWidth: 100,
        hide: true
      }

      const payCurrencyCol = {
        headerName: this.$t('staff.field.payCurrency'),
        field: 'payCurrency',
        minWidth: 100,
        hide: true
      }

      const companyCol = {
        headerName: this.$t('staff.field.company'),
        field: 'company',
        minWidth: 100,
        hide: true
      }

      const staffTypeCol = {
        headerName: this.$t('staff.field.type'),
        field: 'staffType',
        cellRenderer: 'staffTypeCellRenderer',
        minWidth: 100,
        hide: true
      }

      const identifierCol = {
        headerName: self.$t('field.identifier_full'),
        field: 'identifier',
        minWidth: 100,
        hide: true
      }

      const emailCol = {
        headerName: self.$t('staff.field.email'),
        field: 'email',
        minWidth: 100,
        hide: true
      }

      const phoneCol = {
        headerName: self.$t('staff.field.phones'),
        field: 'phone',
        minWidth: 100,
        hide: true,
        cellRenderer: 'arrayCellRenderer',
        cellRendererParams: { labelField: 'data' }
      }

      const socialsCol = {
        headerName: self.$t('staff.field.socials'),
        field: 'socials',
        minWidth: 100,
        hide: true,
        cellRenderer: 'arrayCellRenderer',
        cellRendererParams: { labelField: 'data' }
      }

      const websitesCol = {
        headerName: self.$t('staff.field.websites'),
        field: 'websites',
        minWidth: 100,
        hide: true
      }

      const statusCol = {
        headerName: self.$t('staff.field.status'),
        field: 'status',
        minWidth: 100,
        hide: true,
        sortable: false
      }

      const startDateCol = {
        headerName: self.$t('staff.field.startDate'),
        field: 'startDate',
        cellRenderer: 'dateTimeCellRenderer',
        minWidth: 100,
        hide: true
      }

      const endDateCol = {
        headerName: self.$t('staff.field.endDate'),
        field: 'endDate',
        cellRenderer: 'dateTimeCellRenderer',
        minWidth: 100,
        hide: true
      }
      
      const tagCol = {
        headerName: self.$t('staff.field.tag'),
        field: 'tag',
        minWidth: 100,
        hide: true
      }
      
      const colorCol = {
        headerName: self.$t('field.color'),
        field: 'color',
        cellRenderer: 'colorCellRenderer',
        minWidth: 100,
        hide: true
      }
      
      if(gridType == 'list') {
        const uuIdFieldName = 'uuId'
        this.gridOptions = gridOptions(uuIdFieldName, self);
        positionCol.hide = false;
        // this.columnDefs = [
        const colDefs = [
          lastNameCol(uuIdFieldName)
          , firstNameCol
          , emailCol
          , positionCol
          , locationCol
          , companyCol
          , departmentCol
          , resourcesCol
          , skillCol
          , payAmountCol
          , payCurrencyCol
          , payFrequencyCol
          , staffTypeCol
          , phoneCol
          , socialsCol
          , websitesCol
          , startDateCol
          , endDateCol
          , identifierCol
          , statusCol
          , tagCol
          , colorCol
        ];
        this.defaultColDef = defaultColDef;
        this.context = context;
        const linkedEntities = [
          { selector: 'STAFF.LOCATION', field: 'location', properties: ['LOCATION'] },
          { selector: 'STAFF.DEPARTMENT', field: 'department', properties: ['DEPARTMENT'] },
          { selector: 'STAFF.RESOURCE', field: 'resources', properties: ['RESOURCE'] },
          { selector: 'STAFF.SKILL', field: 'skills', properties: ['SKILL'] },
          { selector: 'STAFF', field: 'payAmount', properties: ['payCurrency'] },
        ]
        this.$store.dispatch('data/schemaAPI', {type: 'api', opts: 'brief' })
        .finally(() => {
          filterOutViewDenyProperties(colDefs, 'STAFF', linkedEntities);
          colDefs.sort(columnDefSortFunc);
          this.columnDefs = colDefs;
        });

      } else if (gridType == 'recommended') {
        const durationCol = {
          headerName: this.$t('staff.field.duration'),
          field: 'duration',
          minWidth: 200,
          hide: false
        }
        skillCol.hide = false;
        const target = this.recommended;
        const uuIdFieldName = 'staffUUID';
        target.gridOptions = gridOptions(uuIdFieldName, target);
        // target.columnDefs = [
        const colDefs = [
          lastNameCol(uuIdFieldName)
          , firstNameCol
          , positionCol
          , locationCol
          , departmentCol
          , skillCol
          , durationCol
          , payAmountCol
          , payCurrencyCol
          , payFrequencyCol
          , staffTypeCol
          , companyCol
          , identifierCol
        ];
        defaultColDef.sortable = false;
        target.defaultColDef = defaultColDef;
        target.context = context;
        const linkedEntities = [
          { selector: 'STAFF.LOCATION', field: 'location', properties: ['LOCATION'] },
          { selector: 'STAFF.DEPARTMENT', field: 'department', properties: ['DEPARTMENT'] },
          { selector: 'STAFF.RESOURCE', field: 'resources', properties: ['RESOURCE'] },
          { selector: 'STAFF.SKILL', field: 'skills', properties: ['SKILL'] }
        ]
        this.$store.dispatch('data/schemaAPI', {type: 'api', opts: 'brief' })
        .finally(() => {
          filterOutViewDenyProperties(colDefs, 'STAFF', linkedEntities);
          target.columnDefs = colDefs;
        });

      } else if (gridType == 'generic') {
        skillCol.hide = false;
        payAmountCol.hide = false;
        const target = this.generic;
        const uuIdFieldName = 'uuId';
        
        target.gridOptions = gridOptions(uuIdFieldName, target);
        payFrequencyCol.hide = false;
        // target.columnDefs = [
        const colDefs = [
          nameCol(uuIdFieldName)
          , skillCol
          , locationCol
          , payAmountCol
          , payCurrencyCol
          , payFrequencyCol
          , resourcesCol
          , departmentCol
          , staffTypeCol
          , identifierCol
          , tagCol
          , colorCol
        ];
        target.defaultColDef = defaultColDef;
        target.context = context;
        const linkedEntities = [
          { selector: 'STAFF.LOCATION', field: 'location', properties: ['LOCATION'] },
          { selector: 'STAFF.DEPARTMENT', field: 'department', properties: ['DEPARTMENT'] },
          { selector: 'STAFF.RESOURCE', field: 'resources', properties: ['RESOURCE'] },
          { selector: 'STAFF.SKILL', field: 'skills', properties: ['SKILL'] }
        ]
        this.$store.dispatch('data/schemaAPI', {type: 'api', opts: 'brief' })
        .finally(() => {
          filterOutViewDenyProperties(colDefs, 'STAFF', linkedEntities);
          target.columnDefs = colDefs;
        });
      }
    },
    isEditable(params) {
      if (typeof params.data !== 'undefined' &&
          typeof params.data.name !== 'undefined' &&
          typeof params.data.staff === 'undefined' &&
          typeof params.data.genericStaff === 'undefined') {
        return false; // can't edit departments
      }
      return true;
    },
    onGridReady(params) {
      this.gridColumnApi = this.gridOptions.columnApi;

      const self = this;
      const updateData = () => {
        params.api.setServerSideDatasource(new ServerSideDatasource(self));
      };

      updateData();
      
    },
    async prepareStaffList() {
      
      const data = { start: 0, limit: -1};
      if (Object.keys(this.settings).length === 0) {
        await this.loadViewProfile(); // make sure the booking rule is loaded
      }
      
      let bookingrule = null;
      if (this.projectIds.length > 0 && this.bookings) {
        bookingrule = ['STAFF.BOOKING.PROJECT.uuId', 'within', this.projectIds.join('|')];   
      }
      
      const companyrule = this.companyrule;
      const departmentrule = this.departmentrule;
      const skillrule = this.skillrule;
      const locationrule = this.locationrule;
      const resourcerule = this.resourcerule;
      if (companyrule &&
               departmentrule) {
        if (bookingrule) {
          data.filter = [
            '_and_', 
            bookingrule,
            companyrule,
            departmentrule
          ];
        }
        else {
          data.filter = [
            '_and_', 
            companyrule,
            departmentrule
          ];
        }         
      }
      else if (skillrule) {
        if (bookingrule) {
          data.filter = [
            '_and_', 
            bookingrule,
            skillrule
          ];
        }
        else {
          data.filter = [skillrule];
        }
      }
      else if (locationrule) {
        if (bookingrule) {
          data.filter = [
            '_and_', 
            bookingrule,
            locationrule
          ];
        }
        else {
          data.filter = [locationrule];
        }
      }
      else if (resourcerule) {
        if (bookingrule) {
          data.filter = [
            '_and_', 
            bookingrule,
            resourcerule
          ];
        }
        else {
          data.filter = [resourcerule];
        }
      }
      else if (companyrule) {
        if (bookingrule) {
          data.filter = [
            '_and_', 
            bookingrule,
            companyrule
          ];
        }
        else {
          data.filter = [companyrule];
        }
      }
      else if (bookingrule) {
        data.filter = [bookingrule];
      }
      
      if (this.staffListUuIds) {
        data.holders = this.staffListUuIds;
      }
      
      await staffService.list(data).then((response) => {
        this.staffUuIds = [];
        for (let i = 0; i < response.data.length; i++) {
          this.staffUuIds.push({ uuId: response.data[i].uuId });
        }
      })
      .catch(e => {
        throw e;
      });
    },
    async onRecommendedGridReady(params) {
      this.recommended.gridColumnApi = this.recommended.gridOptions.columnApi;

      const updateData = () => {
        params.api.setServerSideDatasource(new RecommendedDatasource(this, this.recommended));
      };
    
      await this.prepareStaffList()
      .catch(e => {
        if (e != null && e.response != null && e.response.status == 403) {
          this.showNoRowsOverlay(this.recommended, this.$t('entity_selector.error.insufficient_permission_to_show_data'))
        } else {
          console.error(e); // eslint-disable-line no-console
        }
      });
      this.userId = this.$store.state.authentication.user.uuId;
      recommendationProfileService.list(this.userId).then((response) => {  
        const profileData = response.data[response.data.jobCase];
        if (profileData.length === 0) {
          this.recommended.settings = [];
        }
        else {
          this.recommended.settings = profileData[0];
          this.rectifyInvalidRecommendedSettings();
        }
        updateData();
      })
      .catch(e => {
        if (e != null && e.response != null && e.response.status == 403) {
          this.showNoRowsOverlay(this.recommended, this.$t('entity_selector.error.insufficient_permission_to_show_data'))
        } else {
          console.error(e); // eslint-disable-line no-console
        }
      });
    },
    onOrgChartGridReady(/** params */) {
      this.orgChart.gridColumnApi = this.orgChart.gridOptions.columnApi;
      if (this.staffDataLoaded &&
          this.genericDataLoaded) {
        this.reloadOrgChart();
      }
    },
    onGenericReady(params) {
      this.generic.gridColumnApi = this.generic.gridOptions.columnApi;
      
      const modalSelf = this;
      const self = this.generic;
      const updateData = () => {
        params.api.setServerSideDatasource(new GenericDatasource(self, modalSelf));
      };
      updateData();
    },
    buildParams({ request: {sortModel, endRow, startRow} }) {
      const self = this;
      const params = {
        start: !self.exportData ? startRow : 0,
        limit: !self.exportData ? endRow - startRow + 1 : -1,
      };
      params.ksort = []
      params.order = []
      for(let i = 0, len = sortModel.length; i < len; i++) {
        params.ksort.push(sortModel[i].colId === 'uuId' ? 'lastName' : sortModel[i].colId === 'skills' ? 'skillName' : sortModel[i].colId);
        params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr');
      }
      params.active = this.activeStaff;
      params.inactive = this.inactiveStaff;
      
      let bookingrule = null;
      if (this.projectIds.length > 0 && this.bookings) {
        bookingrule = ['STAFF.BOOKING.PROJECT.uuId', 'within', this.projectIds.join('|')];   
      }
      
      const companyrule = this.companyrule;
      const departmentrule = this.departmentrule;
      const skillrule = this.skillrule;
      const locationrule = this.locationrule;
      const resourcerule = this.resourcerule;
      if (companyrule && departmentrule) {
        if (this.searchFilter === '') {
          if (bookingrule) {
            params.filter = [
              '_and_', 
              bookingrule,
              companyrule,
              departmentrule
            ];
          }
          else {
            params.filter = [
              '_and_', 
              companyrule,
              departmentrule
            ];
          }
        }
        else {
          if (bookingrule) {
            params.filter = [
              '_or_', [
                  ['STAFF.firstName', 'has', this.searchFilter]
                , ['STAFF.lastName', 'has', this.searchFilter]
                , ['STAFF.identifier', 'has', this.searchFilter]
                , ['STAFF.COMPANY.name', 'has', this.searchFilter]
                , ['STAFF.DEPARTMENT.name', 'has', this.searchFilter]
                , ['STAFF.email', 'has', this.searchFilter]
                , ['STAFF.position', 'has', this.searchFilter]
                , ['STAFF.LOCATION.name', 'has', this.searchFilter]
                , ['STAFF.TAG.name', 'has', this.searchFilter]
                , ['STAFF.SKILL.name', 'has', this.searchFilter]
              ],
              bookingrule,
              companyrule,
              departmentrule
            
            ];
          }
          else {
            params.filter = [
              '_or_', [
                  ['STAFF.firstName', 'has', this.searchFilter]
                , ['STAFF.lastName', 'has', this.searchFilter]
                , ['STAFF.identifier', 'has', this.searchFilter]
                , ['STAFF.COMPANY.name', 'has', this.searchFilter]
                , ['STAFF.DEPARTMENT.name', 'has', this.searchFilter]
                , ['STAFF.email', 'has', this.searchFilter]
                , ['STAFF.position', 'has', this.searchFilter]
                , ['STAFF.LOCATION.name', 'has', this.searchFilter]
                , ['STAFF.TAG.name', 'has', this.searchFilter]
                , ['STAFF.SKILL.name', 'has', this.searchFilter]
              ],
              companyrule,
              departmentrule
            ];
          }
        }
      }
      else if (companyrule) {
        if (this.searchFilter === '') {
          if (bookingrule) {
            params.filter = [
              '_and_', 
              bookingrule,
              companyrule
            ];
          }
          else {
            params.filter = [companyrule];
          }
        }
        else {
          if (bookingrule) {
            params.filter = [
              '_or_', [
                  ['STAFF.firstName', 'has', this.searchFilter]
                , ['STAFF.lastName', 'has', this.searchFilter]
                , ['STAFF.identifier', 'has', this.searchFilter]
                , ['STAFF.COMPANY.name', 'has', this.searchFilter]
                , ['STAFF.DEPARTMENT.name', 'has', this.searchFilter]
                , ['STAFF.email', 'has', this.searchFilter]
                , ['STAFF.position', 'has', this.searchFilter]
                , ['STAFF.LOCATION.name', 'has', this.searchFilter]
                , ['STAFF.TAG.name', 'has', this.searchFilter]
                , ['STAFF.SKILL.name', 'has', this.searchFilter]
              ],
              bookingrule,
              companyrule
            
            ];
          }
          else {
            params.filter = [
              '_or_', [
                  ['STAFF.firstName', 'has', this.searchFilter]
                , ['STAFF.lastName', 'has', this.searchFilter]
                , ['STAFF.identifier', 'has', this.searchFilter]
                , ['STAFF.COMPANY.name', 'has', this.searchFilter]
                , ['STAFF.DEPARTMENT.name', 'has', this.searchFilter]
                , ['STAFF.email', 'has', this.searchFilter]
                , ['STAFF.position', 'has', this.searchFilter]
                , ['STAFF.LOCATION.name', 'has', this.searchFilter]
                , ['STAFF.TAG.name', 'has', this.searchFilter]
                , ['STAFF.SKILL.name', 'has', this.searchFilter]
              ],
              companyrule
            
            ];
          }
        }
      } else if (skillrule) {
        if (this.searchFilter === '') {
          if (bookingrule) {
            params.filter = [
              '_and_', 
              bookingrule,
              skillrule
            ];
          }
          else {
            params.filter = [skillrule];
          }
        }
        else {
          if (bookingrule) {
            params.filter = [
              '_or_', [
                  ['STAFF.firstName', 'has', this.searchFilter]
                , ['STAFF.lastName', 'has', this.searchFilter]
                , ['STAFF.identifier', 'has', this.searchFilter]
                , ['STAFF.COMPANY.name', 'has', this.searchFilter]
                , ['STAFF.DEPARTMENT.name', 'has', this.searchFilter]
                , ['STAFF.email', 'has', this.searchFilter]
                , ['STAFF.position', 'has', this.searchFilter]
                , ['STAFF.LOCATION.name', 'has', this.searchFilter]
                , ['STAFF.TAG.name', 'has', this.searchFilter]
                , ['STAFF.SKILL.name', 'has', this.searchFilter]
              ],
              bookingrule,
              skillrule
            
            ];
          }
          else {
            params.filter = [
              '_or_', [
                  ['STAFF.firstName', 'has', this.searchFilter]
                , ['STAFF.lastName', 'has', this.searchFilter]
                , ['STAFF.identifier', 'has', this.searchFilter]
                , ['STAFF.COMPANY.name', 'has', this.searchFilter]
                , ['STAFF.DEPARTMENT.name', 'has', this.searchFilter]
                , ['STAFF.email', 'has', this.searchFilter]
                , ['STAFF.position', 'has', this.searchFilter]
                , ['STAFF.LOCATION.name', 'has', this.searchFilter]
                , ['STAFF.TAG.name', 'has', this.searchFilter]
                , ['STAFF.SKILL.name', 'has', this.searchFilter]
              ],
              skillrule
            
            ];
          }
        }
      } else if (locationrule) {
        if (this.searchFilter === '') {
          if (bookingrule) {
            params.filter = [
              '_and_', 
              bookingrule,
              locationrule
            ];
          }
          else {
            params.filter = [locationrule];
          }
        }
        else {
          if (bookingrule) {
            params.filter = [
              '_or_', [
                  ['STAFF.firstName', 'has', this.searchFilter]
                , ['STAFF.lastName', 'has', this.searchFilter]
                , ['STAFF.identifier', 'has', this.searchFilter]
                , ['STAFF.COMPANY.name', 'has', this.searchFilter]
                , ['STAFF.DEPARTMENT.name', 'has', this.searchFilter]
                , ['STAFF.email', 'has', this.searchFilter]
                , ['STAFF.position', 'has', this.searchFilter]
                , ['STAFF.LOCATION.name', 'has', this.searchFilter]
                , ['STAFF.TAG.name', 'has', this.searchFilter]
                , ['STAFF.SKILL.name', 'has', this.searchFilter]
              ],
              bookingrule,
              locationrule
            
            ];
          }
          else {
            params.filter = [
              '_or_', [
                  ['STAFF.firstName', 'has', this.searchFilter]
                , ['STAFF.lastName', 'has', this.searchFilter]
                , ['STAFF.identifier', 'has', this.searchFilter]
                , ['STAFF.COMPANY.name', 'has', this.searchFilter]
                , ['STAFF.DEPARTMENT.name', 'has', this.searchFilter]
                , ['STAFF.email', 'has', this.searchFilter]
                , ['STAFF.position', 'has', this.searchFilter]
                , ['STAFF.LOCATION.name', 'has', this.searchFilter]
                , ['STAFF.TAG.name', 'has', this.searchFilter]
                , ['STAFF.SKILL.name', 'has', this.searchFilter]
              ],
              locationrule
            
            ];
          }
        }
      } else if (resourcerule) {
        if (this.searchFilter === '') {
          if (bookingrule) {
            params.filter = [
              '_and_', 
              bookingrule,
              resourcerule
            ];
          }
          else {
            params.filter = [resourcerule];
          }
        }
        else {
          if (bookingrule) {
            params.filter = [
              '_or_', [
                  ['STAFF.firstName', 'has', this.searchFilter]
                , ['STAFF.lastName', 'has', this.searchFilter]
                , ['STAFF.identifier', 'has', this.searchFilter]
                , ['STAFF.COMPANY.name', 'has', this.searchFilter]
                , ['STAFF.DEPARTMENT.name', 'has', this.searchFilter]
                , ['STAFF.email', 'has', this.searchFilter]
                , ['STAFF.position', 'has', this.searchFilter]
                , ['STAFF.LOCATION.name', 'has', this.searchFilter]
                , ['STAFF.TAG.name', 'has', this.searchFilter]
                , ['STAFF.SKILL.name', 'has', this.searchFilter]
              ],
              bookingrule,
              resourcerule
            
            ];
          }
          else {
            params.filter = [
              '_or_', [
                  ['STAFF.firstName', 'has', this.searchFilter]
                , ['STAFF.lastName', 'has', this.searchFilter]
                , ['STAFF.identifier', 'has', this.searchFilter]
                , ['STAFF.COMPANY.name', 'has', this.searchFilter]
                , ['STAFF.DEPARTMENT.name', 'has', this.searchFilter]
                , ['STAFF.email', 'has', this.searchFilter]
                , ['STAFF.position', 'has', this.searchFilter]
                , ['STAFF.LOCATION.name', 'has', this.searchFilter]
                , ['STAFF.TAG.name', 'has', this.searchFilter]
                , ['STAFF.SKILL.name', 'has', this.searchFilter]
              ],
              resourcerule
            
            ];
          }
        }
      }
      else if (bookingrule) {
        if (this.searchFilter === '') {
          params.filter = [
            bookingrule
          ];
        }
        else {
          params.filter = [
            '_or_', [
                ['STAFF.firstName', 'has', this.searchFilter]
              , ['STAFF.lastName', 'has', this.searchFilter]
              , ['STAFF.identifier', 'has', this.searchFilter]
              , ['STAFF.COMPANY.name', 'has', this.searchFilter]
              , ['STAFF.DEPARTMENT.name', 'has', this.searchFilter]
              , ['STAFF.email', 'has', this.searchFilter]
              , ['STAFF.position', 'has', this.searchFilter]
              , ['STAFF.LOCATION.name', 'has', this.searchFilter]
              , ['STAFF.TAG.name', 'has', this.searchFilter]
              , ['STAFF.SKILL.name', 'has', this.searchFilter]
            ],
            bookingrule
          
          ];
        }
      }
      else {
        params.filter = this.searchFilter;
      }
        
      if (this.staffListUuIds) {
        params.holders = this.staffListUuIds;
      }
      
      return params;
    },
    selectionChanged() {
      const self = this;
      this.gridOptions.api.forEachNode(function(node) {
        if (typeof node.data !== 'undefined') {
          node.setSelected(self.select_state.checked);
        }
      });
    },
    genericSelectionChanged() {
      const self = this.generic;
      self.gridOptions.api.forEachNode(function(node) {
        if (typeof node.data !== 'undefined') {
          node.setSelected(self.select_state.checked);
        }
      });
    },
    updateSelectState(gridApi, target) {
      const totalRows = gridApi.getDisplayedRowCount();
      const selected = gridApi.getSelectedRows().length;
    
      if(target && target.select_state) {
        target.select_state.indeterminate = selected > 0 && selected < totalRows;
        target.select_state.checked = selected === totalRows;
      }
    },
    modalOpen(isNew, selected, isGeneric=false) {
      if(isNew) {
        this.staffId = `STAFF_NEW_${strRandom(5)}`;
      } else {
        if (typeof selected[0] === 'string') {
          this.staffId = selected[0];
        }
        else {
          this.staffId = selected[0].uuId;
        }
      }
      this.isGeneric = isGeneric;
      this.staffShow = true;
      this.resetAlert();
    },
    modalSuccess(payload) {
      if (this.tabList[this.activeTab].name === 'generic') {
        if (this.generic.gridOptions.api) {
          this.generic.gridOptions.api.refreshServerSide({ purge: true });
        }
      } else {
        this.gridOptions.api.refreshServerSide({ purge: true });
        this.reloadOrgChart();
        if (this.recommended.gridOptions.api) {
          this.recommended.gridOptions.api.refreshServerSide({ purge: true });
        }
      }
      this.resetAlert({ msg: payload.msg })
      this.scrollToTop();
    },
    rowDelete(selected) {
      if (typeof selected[0] === 'string') {
        this.toDeleteIds = selected.map(i => { return { uuId: i } });
      }
      else {
        this.toDeleteIds = selected.map(i => { return { uuId: i.uuId } });
      }
      this.confirmDeleteShow = true;
    },
    async confirmDeleteOk(){ 
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('staff.progress.deleting');
      const currentTab = this.tabList[this.activeTab].name;
      const currentGridOptions = currentTab === 'list'? this.gridOptions : this[currentTab].gridOptions;
      const selectedNodes = currentGridOptions.api.getSelectedNodes();
      const toDeleteIdNames = selectedNodes.map(node => { return { uuId: node.data.uuId, name: node.data.name != null? node.data.name : node.data.label } });
      const toDeleteIds = this.toDeleteIds;
      let deletedIds = [];
      let alertState = alertStateEnum.SUCCESS;
      let alertMsg = this.$t(`staff.delete${toDeleteIds.length > 1? '_plural':''}`);
      let alertMsgDetailTitle = null;
      let alertMsgDetailList = [];
      

      await staffService.remove(this.toDeleteIds)
      .then(response => {
        if (response.status == 207) {
          alertState = alertStateEnum.WARNING;
          alertMsg = this.$t('staff.delete_partial');
          alertMsgDetailTitle = this.$t(`staff.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
          const feedbackList = response.data[response.data.jobCase];
          for (let i = 0, len = feedbackList.length; i < len; i++) {
            const feedback = feedbackList[i];
            const targetId = toDeleteIds[i].uuId;
            if (feedback.clue == 'OK') {
              deletedIds.push(targetId);
              continue;
            }
            const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
            alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
          }
        } else {
          deletedIds = toDeleteIds.map(i => i.uuId);
        }
      })
      .catch(e => {
        alertState = alertStateEnum.ERROR;
        alertMsg = this.$t(`staff.error.delete_failure${toDeleteIds.length > 1? '_plural' : ''}`);
        if (e.response && e.response.status == 422) {
          alertMsgDetailTitle = this.$t(`staff.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
          const feedbackList = e.response.data[e.response.data.jobCase];
          for (let i = 0, len = feedbackList.length; i < len; i++) {
            const feedback = feedbackList[i];
            if (feedback.clue == 'OK') {
              continue;
            }
            const targetId = toDeleteIds[i].uuId;
            const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
            alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
          }
        }
      });

      const alertPayload = {
        msg: alertMsg,
        alertState: alertState
      }
      if (alertMsgDetailList.length > 0) {
        alertPayload.details = alertMsgDetailList;
        alertPayload.detailTitle = alertMsgDetailTitle;
      }

      if (alertState == alertStateEnum.SUCCESS) {
        this.selected = [];
        if (this.tabList[this.activeTab].name === 'generic') {
          this.generic.gridOptions.api.refreshServerSide({ purge: true });
        } else {
          this.gridOptions.api.refreshServerSide({ purge: true });
          this.reloadOrgChart();
        }
        this.$emit('delete', { ids: deletedIds });
      }
      this.inProgressShow = false;
      this.resetAlert(alertPayload);
    },
    httpAjaxError(e) {
      const response = e.response;
      let alertMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        alertMsg = this.$t('error.authorize_action');
      }
      this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.id}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    },
    updateSelected(newValue) {
      this.selected.splice(0, this.selected.length, ...newValue);
    },
    updateModalShow(newValue) {
      this.modalShow = newValue;
    },
    ok() {
      const details = this.gridOptions.api.getSelectedNodes().map(i => { 
        return {
          uuId: i.data.uuId
          , name: i.data.label
          , genericStaff: false 
          , rebates: i.data.rebates
        } 
      });
      const selected = this.selected;
      const idsWithDetails = details.map(i => i.uuId);
      const idsOfMissingDetails = this.selected.filter(i => !idsWithDetails.includes(i));

      //Get the missing staff name from orgGrid grid data
      for(const id of idsOfMissingDetails) {
        const node = this.orgChart.gridOptions.api.getRowNode(id);
        if(node) {
          details.push({ 
            uuId: id
            , name: node && node.data? node.data.name : null
            , genericStaff: false
            , rebates: node.data.rebates });
        }
      }

      const recommendedGridApi = this.recommended.gridOptions.api;
      for (let i = 0, len = this.recommended.selected.length; i < len; i++) {
        selected.push(this.recommended.selected[i]);
        const node = recommendedGridApi.getRowNode(this.recommended.selected[i]);
        
        details.push({
          uuId: this.recommended.selected[i]
          , name: node && node.data? `${node.data.firstName} ${node.data.lastName}` : null
          , duration: node.data.duration
          , genericStaff: false });
      }

      for (let i = 0, len = this.orgChart.selected.length; i < len; i++) {
        selected.push(this.orgChart.selected[i].uuId);
        details.push({
          uuId: this.orgChart.selected[i].uuId
          , name: this.orgChart.selected[i].name
          , genericStaff: false
          , rebates: this.orgChart.selected[i].rebates });
      }

      const genericGridApi = this.generic.gridOptions.api;
      for (let i = 0, len = this.generic.selected.length; i < len; i++) {
        const id = this.generic.selected[i];
        selected.push(id);
        const node = genericGridApi.getRowNode(id);
        details.push({
          uuId: id
          , name: node && node.data? node.data.name : null
          , genericStaff: true
          , rebates: node.data.rebates });
      }

      this.$emit('ok', { ids: [...selected], details: details });
      this.$emit('input', [...selected]);
      
    },
    hidden() {
      this.selected.splice(0, this.selected.length);
      this.recommended.selected.splice(0, this.recommended.selected.length);
      this.orgChart.selected.splice(0, this.orgChart.selected.length);
      this.generic.selected.splice(0, this.generic.selected.length);
      this.$emit('update:show', false);
      this.$emit('cancel');
    },
    openDetail(id, params, { isGeneric=false }={}){
      this.staffId = id;
      this.isGeneric = isGeneric;
      this.staffShow = true;
      this.resetAlert();
    },
    detailLinkLabel(params) {
      if (params && params.data && params.data.lastName) {
        return params.data.lastName;
      }
      else if (params && params.data && params.data.name) {
        return params.data.name;
      }
      
      return '';
    },
    applyFilter(pattern) {
      this.searchFilter = pattern;
      
      this.searchFilterTerm = this.settings[`staff_selector_search`] = this.searchFilter;
      this.updateViewProfile()
      
      this.gridOptions.api.refreshServerSide({ purge: true });
    },
    applyGenericFilter(pattern) {
      this.generic.searchFilter = pattern;
      
      
      this.generic.searchFilterTerm = this.settings[`staff_generic_selector_search`] = this.generic.searchFilter;
      this.updateViewProfile()
      
      this.generic.gridOptions.api.refreshServerSide({ purge: true });
    },
    applyRecommendedFilter(pattern) {
      this.recommended.searchFilter = pattern
      
      this.recommended.searchFilterTerm = this.settings[`staff_recommended_selector_search`] = this.recommended.searchFilter;
      this.updateViewProfile()
      
      this.recommended.gridOptions.api.refreshServerSide({ purge: true });
    },
    async reloadOrgChart() {
      if (this.orgChart == null || this.orgChart.gridOptions == null || this.orgChart.gridOptions.api == null) {
        return;
      }
      const api = this.orgChart.gridOptions.api;
      
      if (this.lackOfMandatoryField(this.queryMandatoryFields_orgChart)) {
        this.showNoRowsOverlay(this.orgChart, this.$t('entity_selector.error.insufficient_permission_to_show_data'))
        return
      } else {
        this.orgChart.noRowsMessage = null
      }


      api.showLoadingOverlay();
      let companies = null;
      const { cmdList, departmentFields, companyFields } = prepareDepartmentTreeRequest(this, true);
       
      const responseData = await compositeService.exec(cmdList, true)
      .catch(e => {
        // console.error(error) // eslint-disable-line no-console
        if (e != null && e.response != null && 
            e.response.data != null && e.response.data.jobClue != null) {
          if (e.response.data.jobClue.clue == 'Forbidden_api') {
            this.showNoRowsOverlay(this.orgChart, this.$t('entity_selector.error.insufficient_permission_to_show_data'))
          }
        }
        return null;
      });

      const companyKeys = getKeysWithoutRedactedFields(companyFields, { data: responseData.data.feedbackList[0] });
      const departmentKeys = getKeysWithoutRedactedFields(departmentFields, { data: responseData.data.feedbackList[1] });
      const staffKeys = getKeysWithoutRedactedFields(companyFields, { data: responseData.data.feedbackList[2] });
      companies = responseData.data.feedbackList[0].fetch.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[companyKeys[j]] = i[j];
        }
        return result;
      });
      let departments = responseData.data.feedbackList[1].fetch.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[departmentKeys[j]] = i[j];
        }
        return result;
      });
      let staffNoDep = responseData.data.feedbackList[2].fetch.map(i => {
        const result = {}
        for(let j = 0, len = i.length; j < len; j++) {
          result[staffKeys[j]] = i[j];
        }
        return result;
      });

      if (companies == null) {
        this.orgChart.rowData = [];
        api.setRowData(this.rowData);
        return;
      }
      
      const tree = makeTree(companies, departments, staffNoDep, this.orgChartInactiveStaff, this.orgChartActiveStaff, this.companies.length > 0 ? this.companies : null, {}, this.skill || this.location || this.resource || this.staffListUuIds ? this.allStaff : null);
      
      const orgSelectedStaffIds = this.orgChart.gridOptions && this.orgChart.gridOptions.api? this.orgChart.gridOptions.api.getSelectedNodes().map(i => i.key): [];
      this.treeData = pruneTree(this, tree, this.departments.length !== 0 ? this.departments[0].name : this.orgChart.searchFilter);
      this.orgChart.rowData = extractRowsFromData(this, '', this.treeData, this.orgChart.listStaff).filter(r => !r.staff || (r.genericStaff && this.orgChartGenericStaff) || (!r.genericStaff && this.orgChartRealStaff));
      
      if (this.orgChart.gridOptions.api) {
        this.orgChart.gridOptions.api.deselectAll();
      }
      setTimeout(() => {
        this.processNodes(orgSelectedStaffIds);
      }, 100);
    },
    applyOrgChartFilter(pattern) {
      this.orgChart.searchFilter = pattern;
      
      this.orgChart.searchFilterTerm = this.settings[`staff_orgchart_selector_search`] = this.orgChart.searchFilter;
      this.updateViewProfile()
      
      this.reloadOrgChart();
    },
    showRecommendationSettings() {
      this.recommendationSettingsShow = true;
    },
    recommendationSettingsSuccess(result) {
      this.recommendationSettingsShow = false;
      this.recommended.settings = result;
      this.recommended.gridOptions.api.refreshServerSide({ purge: true });
    },
    resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertMsg = msg;
      this.alertState = alertState;
      this.alertMsgDetails.title = detailTitle;
      const list = this.alertMsgDetails.list;
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details);
      } else {
        list.splice(0, list.length);
      }
    },
    showDuplicateDialog(selected) {
      this.resetAlert();
      this.duplicateRefId = selected[0];
      const data = this.gridOptions.api.getRowNode(this.duplicateRefId).data;
      this.duplicateFirstname = data.firstName;
      this.duplicateLastname = data.lastName;
      this.duplicateEmail = '';
      this.isGeneric = false;
      this.duplicateShow = true;
    },
    showDuplicateDialogForGeneric(selected) {
      this.resetAlert();
      this.duplicateRefId = selected[0];
      const data = this.generic.gridOptions.api.getRowNode(this.duplicateRefId).data;
      this.duplicateName = data.name;
      this.isGeneric = true;
      this.duplicateShow = true;
    },
    showDuplicateDialogSelectedObj(selected /**, gridApi */) {
      this.resetAlert();
      this.duplicateRefId = selected[0].uuId;
      this.duplicateFirstname = selected[0].staffFirstName;
      this.duplicateLastname = selected[0].staffLastName;
      this.duplicateEmail = '';
      this.isGeneric = false;
      this.duplicateShow = true;
    },
    duplicateOk() {
      this.errors.clear();
      this.duplicateEntity();
    },
    duplicateEntity: debounce(function() {
      this.duplicateInProgress = true;
      if(this.isGeneric) {
        if(!this.duplicateName || this.duplicateName.trim().length < 1) {
          this.errors.add({
            field: `duplicateName`,
            msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('staff.field.name')])
          });
        }
      } else {
        if(!this.duplicateEmail || this.duplicateEmail.trim().length < 1) {
          this.errors.add({
            field: `duplicateEmail`,
            msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('staff.field.email')])
          });
        }
        if(!this.duplicateLastname || this.duplicateLastname.trim().length < 1) {
          this.errors.add({
            field: `duplicateLastname`,
            msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('staff.field.lastName')])
          });
        }
      }
      
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          
          let data = null;
          if (this.isGeneric) {
            data = {
              firstName: this.duplicateName //Use 'firstName' for name till backend corrects the name property
              , genericStaff: true
            }
          } else {
            data = {
              email: this.duplicateEmail
            , firstName: this.duplicateFirstname
            , lastName: this.duplicateLastname
            , genericStaff: false
            }
          }
          staffService.clone(this.duplicateRefId, data)
          .then(() => {
            this.resetAlert({ msg: this.$t('staff.duplicate') });
            if (this.tabList[this.activeTab].name === 'generic') {
              if (this.generic.gridOptions.api) {
                this.generic.gridOptions.api.refreshServerSide({ purge: true });
              }
            } else {
              this.gridOptions.api.refreshServerSide({ purge: true });
              if (this.recommended.gridOptions.api) {
                this.recommended.gridOptions.api.refreshServerSide({ purge: true });
              }
              this.reloadOrgChart();
            }
            
          })
          .catch(e => {
            let  alertMsg = this.$t('error.clone.failure', [this.$t('entityType.STAFF')]);
            if(e.response && e.response.data && e.response.data.jobClue != null) {
              const clue = e.response.data.jobClue.clue;
              let signal = false;
              if ('Not_unique_key' === clue) { 
                alertMsg = this.$i18n.t('staff.error.not_unique_key', [this.$i18n.t('staff.field.email')]);
                this.errors.add({
                  field: `duplicateEmail`,
                  msg: this.$i18n.t('staff.error.not_unique_key', [this.$i18n.t('staff.field.email')])
                });
                signal = true; //Set true to signal not to close dialog.
              } else if ('Unknown_holder' === clue) {
                alertMsg = this.$t('staff.error.duplicate_not_found');
              }
              if (!signal) {
                //Only show alert feedback when dialog is ready to be closed.
                this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR })
              }
              this.scrollToTop();
              this.duplicateInProgress = false;
              return signal;
            } else {
              this.duplicateInProgress = false;
              this.httpAjaxError(e);
            }
          })
          .then(doNotCloseDialog => {
            if(!doNotCloseDialog) {
              this.duplicateShow = false;
              this.errors.clear();
            }
            //Make sure the dialog is closed before reenable duplicate button to avoid button spamming.
            this.$nextTick(() => {
              this.duplicateInProgress = false;
            });
          });
        } else {
          this.duplicateInProgress = false;
        }
      });
    }, 100),
    duplicateCancel() {
      this.duplicateShow = false;
      this.errors.clear();
    },
    selectorNavClick(event) {
      /**
       * Update activeTab with the user choice.
       */
      const liElem = event.srcElement.closest('li');
      const activeName = liElem.getAttribute('name');
      
      const tabList = this.tabList;
      for(let i = 0, len = tabList.length; i < len; i++) {
        const tab = tabList[i];
        if(tab.name == activeName) {
          this.activeTab = i;
        }
      }
    },
    async updateTabLink(activeIndex) {
      //Update the active state/style of links.
      const navbarElem = this.$refs['selector-nav']; 
      if (navbarElem != null) {
        const childs = navbarElem.$el.querySelectorAll('li');
        for (let i = 0; i < childs.length; i++) {
          if (activeIndex == i) {
            childs[i].classList.add('active');
          } else if(childs[i] != null && childs[i].classList != null) {
            childs[i].classList.remove('active');
          }
        }
      }

      
    },
    delay(t, v) {
      return new Promise(function(resolve) { 
        setTimeout(resolve.bind(null, v), t)
      });
    },
    onTabSwitch(newTabIndex /**, prevTabIndex */) {
      this.updateTabLink(newTabIndex);

      //When switching between tabs, clear all selection in previous tab
      const newTab = this.tabList[newTabIndex].name;
      if (newTab == 'list') {
        this.select_state.checked = false;
        if(this.recommended && this.recommended.gridOptions.api) {
          this.recommended.gridOptions.api.deselectAll();
        }
        if(this.orgChart && this.orgChart.gridOptions.api) {
          this.orgChart.gridOptions.api.deselectAll();
        }
        if(this.generic && this.generic.gridOptions.api) {
          this.generic.gridOptions.api.deselectAll();
        }
      } else if (newTab == 'recommended') {
        if (this.gridOptions.api) {
          this.gridOptions.api.deselectAll();
        }
        if(this.orgChart && this.orgChart.gridOptions.api) {
          this.orgChart.gridOptions.api.deselectAll();
        }
        if(this.generic && this.generic.gridOptions.api) {
          this.generic.gridOptions.api.deselectAll();
        }
      } else if (newTab == 'orgChart') {
        if (this.gridOptions.api) {
          this.gridOptions.api.deselectAll();
        }
        if(this.recommended && this.recommended.gridOptions.api) {
          this.recommended.gridOptions.api.deselectAll();
        }
        if(this.generic && this.generic.gridOptions.api) {
          this.generic.gridOptions.api.deselectAll();
        }
      } else if (newTab == 'generic') {
        this.generic.select_state.checked = false;
        if (this.gridOptions.api) {
          this.gridOptions.api.deselectAll();
        }
        if(this.recommended && this.recommended.gridOptions.api) {
          this.recommended.gridOptions.api.deselectAll();
        }
        if(this.orgChart && this.orgChart.gridOptions.api) {
          this.orgChart.gridOptions.api.deselectAll();
        }
      }
    },
    updateViewProfile() {
      viewProfileService.update([this.settings], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    createViewProfile() {
      viewProfileService.create([this.settings],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.settings.uuId = data[0].uuId;
        this.recommended_newToProfile = true;
        this.list_newToProfile = true;
        this.generic_newToProfile = true;
        this.orgchart_newToProfile = true;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    async loadViewProfile() {
      const self = this;
      await this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {  
        const profileData = value;
        if (profileData.length === 0) {
          self.createViewProfile();
        }
        else {
          self.settings = profileData[0];
          if (typeof self.settings.staff_selector_recommended !== 'undefined' && self.recommended.gridOptions.api != null) {
            self.loadColumnSettings(self.recommended, self.settings.staff_selector_recommended);
          } else {
            self.recommended_newToProfile = true;
          }
          if (typeof self.settings.staff_selector_list !== 'undefined') {
            self.loadColumnSettings(self, self.settings.staff_selector_list);
            self.coloring.none = self.settings.staff_selector_coloring ? self.settings.staff_selector_coloring.none : true;
            self.coloring.staff = self.settings.staff_selector_coloring ? self.settings.staff_selector_coloring.staff : false;
            self.coloring.department = self.settings.staff_selector_coloring ? self.settings.staff_selector_coloring.department : false;
            self.coloring.company = self.settings.staff_selector_coloring ? self.settings.staff_selector_coloring.company : false;
            self.coloring.skill = self.settings.staff_selector_coloring ? self.settings.staff_selector_coloring.skill : false;
            self.coloring.resource = self.settings.staff_selector_coloring ? self.settings.staff_selector_coloring.resource : false;
            self.coloring.location = self.settings.staff_selector_coloring ? self.settings.staff_selector_coloring.location : false;
          } else {
            self.list_newToProfile = true;
          }
          if (typeof self.settings.staff_selector_generic !== 'undefined') {
            self.loadColumnSettings(self.generic, self.settings.staff_selector_generic);
            self.generic.coloring.none = self.settings.staff_selector_generic_coloring ? self.settings.staff_selector_generic_coloring.none : true;
            self.generic.coloring.staff = self.settings.staff_selector_generic_coloring ? self.settings.staff_selector_generic_coloring.staff : false;
            self.generic.coloring.department = self.settings.staff_selector_generic_coloring ? self.settings.staff_selector_generic_coloring.department : false;
            self.generic.coloring.skill = self.settings.staff_selector_generic_coloring ? self.settings.staff_selector_generic_coloring.skill : false;
            self.generic.coloring.resource = self.settings.staff_selector_generic_coloring ? self.settings.staff_selector_generic_coloring.resource : false;
            self.generic.coloring.location = self.settings.staff_selector_generic_coloring ? self.settings.staff_selector_generic_coloring.location : false;
          } else {
            self.generic_newToProfile = true;
          }
          if (typeof self.settings.staff_selector_orgchart !== 'undefined') {
            self.loadColumnSettings(self.orgChart, self.settings.staff_selector_orgchart);
          } else {
            self.orgchart_newToProfile = true;
          }

          if (self.toggledByAutoAssignStaff) {
            self.activeStaff = true;
            self.inactiveStaff = false;
            self.orgChartActiveStaff = true;
            self.orgChartInactiveStaff = false;
            self.orgChartRealStaff = true;
            self.orgChartGenericStaff = false;
          } else {
            if (typeof self.settings.activeStaff !== 'undefined') {
              self.activeStaff = self.settings.activeStaff;
            }
            if (typeof self.settings.inactiveStaff !== 'undefined') {
              self.inactiveStaff = self.settings.inactiveStaff;
            }
            if (typeof self.settings.orgChartActiveStaff !== 'undefined') {
              self.orgChartActiveStaff = self.settings.orgChartActiveStaff;
            }
            if (typeof self.settings.orgChartInactiveStaff !== 'undefined') {
              self.orgChartInactiveStaff = self.settings.orgChartInactiveStaff;
            }
            if (typeof self.settings.orgChartRealStaff !== 'undefined') {
              self.orgChartRealStaff = self.settings.orgChartRealStaff;
            }
            if (typeof self.settings.orgChartGenericStaff !== 'undefined') {
              self.orgChartGenericStaff = self.settings.orgChartGenericStaff;
            }
          }
          
          if (typeof self.settings['staff_selector_bookings'] !== 'undefined') {
            self.bookings = self.settings['staff_selector_bookings'];
          }
          
          this.searchFilterTerm = typeof this.settings[`staff_selector_search`] !== 'undefined' ? this.settings[`staff_selector_search`] : '';
          if (this.searchFilterTerm !== '') {
            this.applyFilter(this.searchFilterTerm);
          }
          
          this.recommended.searchFilterTerm = typeof this.settings[`staff_recommended_selector_search`] !== 'undefined' ? this.settings[`staff_recommended_selector_search`] : '';
          if (this.recommended.searchFilterTerm !== '') {
            this.applyRecommendedFilter(this.recommended.searchFilterTerm);
          }
          
          this.orgChart.searchFilterTerm = typeof this.settings[`staff_orgchart_selector_search`] !== 'undefined' ? this.settings[`staff_orgchart_selector_search`] : '';
          if (this.orgChart.searchFilterTerm !== '') {
            this.applyOrgChartFilter(this.orgChart.searchFilterTerm);
          }
          
          this.generic.searchFilterTerm = typeof this.settings[`staff_generic_selector_search`] !== 'undefined' ? this.settings[`staff_generic_selector_search`] : '';
          if (this.generic.searchFilterTerm !== '') {
            this.applyGenericFilter(this.generic.searchFilterTerm);
          }
        }
      })
      .catch(e => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadColumnSettings(data, columns) {
      //Set autoGroupColumn
      const autoGroupSetting = columns.find(i => i.colId == 'ag-Grid-AutoColumn');
      if (autoGroupSetting) {
        data.autoGroupColumnDef.width = autoGroupSetting.width;
        data.autoGroupColumnDef.sort = autoGroupSetting.sort;
        if (data.gridOptions.api != null) {
          data.gridOptions.api.setAutoGroupColumnDef({
            ...data.autoGroupColumnDef
          })
        }
      }

      if (data.columnDefs) {
        // order the columns based upon the order in 'columns'
        let idx = 0;
        columns.forEach(function(col) {
          const index = data.columnDefs.findIndex((c) => c.field === col.colId);
          if (index !== -1) {
            data.columnDefs.splice(idx++, 0, data.columnDefs.splice(index, 1)[0]);
          }
        });
        
        for (const column of data.columnDefs) {
          const setting = columns.filter(c => c.colId === column.field);
          if (setting.length === 0) {
            column.hide = true;
          }
          else {
            column.hide = false;
            column.width = setting[0].width;
            column.sort = setting[0].sort;
            column.sortIndex = setting[0].sortIndex;
          }
        }
      }
      
      if (data != null && data.gridOptions != null && data.gridOptions.api != null) {
        data.gridOptions.api.setColumnDefs([]);
        data.gridOptions.api.setColumnDefs(data.columnDefs);
      }
      
      return false;      
    },
    onActiveStaffChange() {
      if (this.alertMsg === this.$t('staff.error.not_found')) {
        this.alertMsg = null;
        this.selected = cloneDeep(this.selectedStaff);
      }
      this.settings.activeStaff = this.activeStaff;
      this.updateViewProfile();
      this.$nextTick(() => {
        if(this.gridOptions.api) {
          this.gridOptions.api.refreshServerSide({ purge: true });
        }
      });
    },
    fileImport() {
      this.docImportShow = true;
    },
    fileImportGeneric() {
      this.docImportGenericShow = true;
    },
    processCellCallback(/**self*/) {
      return function(params) {
        if (params.column.colId.indexOf('uuId') !== -1) {
          return params.node.data.lastName;
        }
        else if (params.column.colId.indexOf('resources') !== -1) {
          return params.node.data.resources.map(i => {
            return `${i.name} (${i.unit})`;
          }).join(', ');
        }
        else if (params.value &&
                 (params.column.colId === 'socials' ||
                 params.column.colId === 'phone')) {
          return params.value.map(i => {
            return `${i.kind}: ${i.data}`;
            }).join(', ');        
        }
        else if (params.column.colId.indexOf('startDate') !== -1) {
          if (params.node.data.startDate === 253402214400000 ||
              params.node.data.startDate === 32503680000000 ||
              params.node.data.startDate === 0) {
            return '';
          }
          return moment(params.node.data.startDate).format();
        }
        else if (params.column.colId.indexOf('endDate') !== -1) {
          if (params.node.data.endDate === 253402214400000 ||
              params.node.data.startDate === 32503680000000 ||
              params.node.data.endDate === 0) {
            return '';
          }
          return moment(params.node.data.endDate).format();
        }
        else if (params.column.colId === 'websites' &&
                 params.value) {
          return params.value.join(', ');
        }
        return params.value;
      }
    },
    processCellCallbackGeneric(/**self*/) {
      return function(params) {
        if (params.column.colId.indexOf('uuId') !== -1) {
          return params.node.data.name;
        }
        return params.value;
      }
    },
    fileExport() {
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('dataview.exporting');
      this.exportData = true;
      
      let listener = () =>{
      
        const keys = this.gridColumnApi
          .getAllColumns()
          .map(column => column.getColId())
        const idx = keys.findIndex(k => k === 'company');
        const item = keys.splice(idx, 1);
        keys.splice(3, 0, item);
        const self = this;
        this.gridOptions.api.exportDataAsExcel({ 
          fileName: 'Staff'
          , sheetName: 'Staff'
          , columnKeys: keys
          , rowHeight: 20
          , processCellCallback: self.processCellCallback(self)
        });
        
        this.exportData = false;
        this.inProgressShow = false;
        this.gridOptions.api.removeEventListener('modelUpdated', listener);
      };
      
      this.gridOptions.api.refreshServerSide({purge: true});
      this.gridOptions.api.addEventListener('modelUpdated', listener);
    },
    fileExportGeneric() {
      const self = this;
      this.generic.gridOptions.api.exportDataAsExcel({ 
        fileName: 'Staff'
        , sheetName: 'Staff'
        , allColumns: true
        , rowHeight: 20
        , processCellCallback: self.processCellCallbackGeneric(self)
      });
    },
    processCellCallbackOrgChart(self) {
      return function(params) {
        if (params.column.colId.indexOf('staff') !== -1) {
          if (params.node.data.staff) {
            return params.node.data.name;
          }
          return null;
        }
        else if (params.column.colId.indexOf('department') !== -1) {
          if (params.node.data.staff) {
            return null;
          }
          return params.node.data.name;
        }
        else if (params.column.colId.indexOf('path') !== -1) {
          const ids = params.node.data.path.split(', ');
          let path = '';
          let parentPath = '';
          for (const id of ids) {
            const parent = self.orgChart.gridOptions.api.getRowNode(parentPath);
            if (parent) {
              path = path !== '' ? `${path}/${parent.data.name}` : parent.data.name;
            }
            parentPath = parentPath === '' ? id : `${parentPath}, ${id}`
          }
          return path;
        }
        return params.value;
      }
    },
    fileExportOrgChart() {
    
      const keys = [];
      keys.push('staff');
      keys.push('position');
      keys.push('department');
      keys.push('identifier');
      keys.push('status');
      keys.push('path');
     
      const self = this;
      this.orgChart.gridOptions.api.exportDataAsExcel({ 
        fileName: 'Staff'
        , sheetName: 'Staff'
        , columnKeys: keys
        , rowHeight: 20
        , processCellCallback: self.processCellCallbackOrgChart(self)
      });
    },
    async docImportOk({ items }) {
      this.docImportShow = false;
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('staff.progress.importing');
      this.resetAlert();
      await this.addStaff(items);
      this.inProgressShow = false;
      if (this.tabList[this.activeTab].name === 'generic') {
        if (this.generic.gridOptions.api) {
          this.generic.gridOptions.api.refreshServerSide({ purge: true });
        }
      } else {
        this.gridOptions.api.refreshServerSide({ purge: true });
        this.reloadOrgChart();
        if (this.recommended.gridOptions.api) {
          this.recommended.gridOptions.api.refreshServerSide({ purge: true });
        }
      }
    },
    async docImportGenericOk({ items }) {
      this.docImportShow = false;
      this.inProgressShow = true;
      await this.addGenericStaff(items);
      this.inProgressShow = false;
      if (this.tabList[this.activeTab].name === 'generic') {
        if (this.generic.gridOptions.api) {
          this.generic.gridOptions.api.refreshServerSide({ purge: true });
        }
      } else {
        this.gridOptions.api.refreshServerSide({ purge: true });
        this.reloadOrgChart();
        if (this.recommended.gridOptions.api) {
          this.recommended.gridOptions.api.refreshServerSide({ purge: true });
        }
      }
    },
    parsePhone(phone) {
      if (phone.includes(':')) {
        const parts = phone.split(': ');
        return { kind: parts[0], data: parts[1] };
      }
      return { kind: 'mobile', data: phone }
    },
    parseSocial(social) {
      if (social.includes(':')) {
        const parts = social.split(': ');
        return { kind: parts[0], data: parts[1] };
      }
      return { kind: 'linkedin', data: social }
    },
    async addStaff(items) {
      this.inProgressLabel = this.$t('staff.progress.importing', [0]);
      let percentage = 0;
      const self = this;
      let cmdList = [];
      let idx = 0;
      cmdList.push({
           "note":"Disable macro calculations"
          ,"invoke"    : "PUT /api/system/features?entity=macros&action=DISABLE"
      });
      for (const item of items) {
      //[{
        //"firstName":"Test",
        //"lastName":"Testerson",
        //"position":"sdfds",
        //"email":"test@jet.com",
        //"payAmount":"100000",
        //"payFrequency":"Annually",
        //"payCurrency":"AUD",
        //"startDate":"1970-01-01",
        //"endDate":"3000-01-01",
        //"websites":[],
        //"socials":[],
        //"phones":[],
        //"identifier":null,
        //"staffType":"Full_Time",
        //"avatarRef":"00000000-0000-0000-0000-000000000000",
        //"bannerRef":"00000000-0000-0000-0000-000000000000",
        //"genericStaff":false}]
        const dateReg = /^\d{4}([./-])\d{2}\1\d{2}$/
        
        if (item.startdate) {
          item.startdate = moment.utc(item.startdate).format('YYYY-MM-DD');
        }
        
        if (item.enddate) {
          item.enddate = moment(item.enddate).format('YYYY-MM-DD');
        }
        
        const data = {
          firstName: item.firstname,
          lastName: item.name,
          position: item.position,
          email: item.email,
          payAmount: item.payamount,
          payFrequency: item.payfrequency,
          payCurrency: item.paycurrency,
          startDate: item.startdate && item.startdate.match(dateReg) ? item.startdate : "1970-01-01",
          endDate: item.enddate && item.enddate.match(dateReg) ? item.enddate : "3000-01-01",
          staffType: item.type,
          genericStaff: false,
          identifier: item.identifier,
          color: item.color
        }
        
        if (item.phone &&
            item.phone.length > 0) {
          data['phones'] = item.phone.split(', ').map(p => { return self.parsePhone(p) });    
        }
        
        if (item.socials &&
            item.socials.length > 0) {
          data['socials'] = item.socials.split(', ').map(p => { return self.parseSocial(p) });    
        }
        
        if (item.websites &&
            item.websites.length > 0) {
          data['websites'] = item.websites.split(', ');    
        }
        
        if (data.staffType === '') {
          delete data['staffType'];
        }
        
        // companyId
        var companyId = null;
        if (item.company &&
            ((Array.isArray(item.company) && item.company.length > 0 && item.company[0].uuId !== null) ||
            (!Array.isArray(item.company) && item.company.uuId !== null))) {
          companyId = Array.isArray(item.company) ? item.company[0].uuId : item.company.uuId;
        }
        else {
          companyId = localStorage.companyId;
        }
          
        const resourceList = []
        
        if (item.resources && item.resources.length > 0 && item.resources[0].uuId !== null) {
          item.resources.forEach(i => {
            resourceList.push({
              uuId: i.uuId, 
              resourceLink: { 
                utilization: i.utilization ? i.utilization : 1.00, 
                quantity: i.quantity 
              }
            });
          });
        }
        
        idx++;
        const idName = `stfk${idx}`;
        cmdList.push(...[{
         "invoke": "/api/staff/add"
        ,"body": [data]
        ,"vars": [ {"name": idName,"path": "$.feedbackList.uuId"} ]
        },
        {
          "invoke": '/api/company/link/staff/add',
          "body": {
            uuId: companyId,
            staffList: [{ uuId: `@{${idName}}` }]
          }
        },
        {
          "invoke": '/api/staff/link/location/add',
          "body": {
            uuId: `@{${idName}}`,
            locationList: [{ uuId: item.location && item.location.uuId ? item.location.uuId : null }]
          }
        }]);
        
        if (resourceList.length !== 0) {
          for (const resource of resourceList) {
            cmdList.push({
              "invoke": '/api/staff/link/resource/add',
              "body": {
                uuId: `@{${idName}}`,
                resourceList: [resource]
              }
            });
          }
        }
                
        if (item.skills && item.skills.length !== 0) {
          for (const skill of item.skills) {
            cmdList.push({
              "invoke": '/api/staff/link/skill/add',
              "body": {"uuId":`@{${idName}}`,
                       "skillList":[{ uuId: skill.uuId, skillLink: {level: skill.skillLink.level}} ]
              }
            });
          }
        }
        
        if (item.department &&
            item.department.length > 0 && item.department[0].uuId !== null) {
          for (const dep of item.department) {
            cmdList.push({
              "invoke": '/api/department/link/staff/add',
              "body": {
                uuId: dep.uuId,
                staffList: [{ uuId: `@{${idName}}` }]
              }
            });
          }
        }
        
        if (item.notes) {
          item.notes.forEach(i => {
            cmdList.push({
              "invoke": `/api/note/add?holder=@{${idName}}`,
              "body": [i]
            });
          });
        }
        
        if (item.tag) {
          const toAdd = [];
          const tags = item.tag.split(',').map(t => { return { name: t.trim() }});
          for (let i = 0; i < tags.length; i++) {
            if (!tags[i].uuId) {
              // get the uuId
              tags[i].uuId = await tagService.list({filter: tags[i].name}).then((response) => {
                if (response.data.length !== 0) {
                  return response.data[0].uuId;
                }
                return null;
              });
              
              if (tags[i].uuId === null) {
                tags[i].uuId = await tagService.create([{name: tags[i].name}]).then((response) => {
                  if (response.data[response.data.jobCase].length !== 0) {
                    return response.data[response.data.jobCase][0].uuId;
                  }
                  return null;
                });
              }
            }
            
            if (tags[i].uuId !== null) {
              toAdd.push({uuId: tags[i].uuId});
            }
          }
          
          for (const tag of toAdd) {
            cmdList.push({
              "invoke": '/api/staff/link/tag/add',
              "body": {
                uuId: `@{${idName}}`,
                tagList: [{ uuId: tag.uuId }]
              }
            });
          }
        }
        
        if (cmdList.length >= 500) {
          // Try to limit the number of api calls sent to the server in each batch
          await compositeService.exec(cmdList)
          .then(response => {
            const feedbackList = response.data.feedbackList;
            if (Array.isArray(feedbackList) && 
                  feedbackList.length > 0 && 
                  feedbackList[0].uuId != null) {
              return feedbackList[0].uuId;
            }
          })
          .catch((e) => {
            this.httpAjaxError(e);
            return null;
          });
          cmdList = [];
        }
        
        percentage++;
        this.inProgressLabel = this.$t('staff.progress.importing', [parseFloat(percentage / items.length * 100).toFixed(0)]);
      }
    
      cmdList.push({
           "note":"Enable macro calculations"
          ,"invoke"    : "PUT /api/system/features?entity=macros&action=ENABLE"
      });
      await compositeService.exec(cmdList)
      .then(response => {
        const feedbackList = response.data.feedbackList;
        if (Array.isArray(feedbackList) && 
              feedbackList.length > 0 && 
              feedbackList[0].uuId != null) {
          return feedbackList[0].uuId;
        }
      })
      .catch((e) => {
        this.httpAjaxError(e);
        return null;
      });
      
    },
    async addGenericStaff(items) {
     this.inProgressLabel = this.$t('staff.progress.importing', [0]);
      let percentage = 0;
      let cmdList = [];
      let idx = 0;
      cmdList.push({
           "note":"Disable macro calculations"
          ,"invoke"    : "PUT /api/system/features?entity=macros&action=DISABLE"
      });
      
      for (const item of items) {

        const data = {
          firstName: item.name,
          position: item.position,
          payAmount: item.payamount,
          payFrequency: item.payfrequency,
          payCurrency: item.paycurrency,
          staffType: item.type,
          genericStaff: true,
          identifier: item.identifier,
          color: item.color,
          startDate:"1970-01-01",
          endDate:"3000-01-01"
        }
        
        if (data.staffType === '') {
          delete data['staffType'];
        }
        
        // companyId
        var companyId = null;
        if (item.company &&
            ((Array.isArray(item.company) && item.company.length > 0 && item.company[0].uuId !== null) ||
            (!Array.isArray(item.company) && item.company.uuId !== null))) {
          companyId = Array.isArray(item.company) ? item.company[0].uuId : item.company.uuId;
        }
        else {
          companyId = localStorage.companyId;
        }
          
        const resourceList = []
        
        if (item.resources && item.resources.length > 0 && item.resources[0].uuId !== null) {
          item.resources.forEach(i => {
            resourceList.push({
              uuId: i.uuId, 
              resourceLink: { 
                utilization: i.utilization ? i.utilization : 1.00, 
                quantity: i.quantity 
              }
            });
          });
        }
        
        idx++;
        const idName = `stfk${idx}`;
        cmdList.push(...[{
         "invoke": "/api/staff/add"
        ,"body": [data]
        ,"vars": [ {"name": idName,"path": "$.feedbackList.uuId"} ]
        },
        {
          "invoke": '/api/company/link/staff/add',
          "body": {
            uuId: companyId,
            staffList: [{ uuId: `@{${idName}}` }]
          }
        },
        {
          "invoke": '/api/staff/link/location/add',
          "body": {
            uuId: `@{${idName}}`,
            locationList: [{ uuId: item.location && item.location.uuId ? item.location.uuId : null }]
          }
        }]);
        
        if (resourceList.length !== 0) {
          for (const resource of resourceList) {
            cmdList.push({
              "invoke": '/api/staff/link/resource/add',
              "body": {
                uuId: `@{${idName}}`,
                resourceList: [resource]
              }
            });
          }
        }
                
        if (item.skills && item.skills.length !== 0) {
          for (const skill of item.skills) {
            cmdList.push({
              "invoke": '/api/staff/link/skill/add',
              "body": {"uuId":`@{${idName}}`,
                       "skillList":[{ uuId: skill.uuId, skillLink: {level: skill.level}} ]
              }
            });
          }
        }
        
        if (item.department &&
            item.department.length > 0 && item.department[0].uuId !== null) {
          for (const dep of item.department) {
            cmdList.push({
              "invoke": '/api/department/link/staff/add',
              "body": {
                uuId: dep.uuId,
                staffList: [{ uuId: `@{${idName}}` }]
              }
            });
          }
        }
        
        if (item.tag) {
          const toAdd = [];
          const tags = item.tag.split(',').map(t => { return { name: t.trim() }});
          for (let i = 0; i < tags.length; i++) {
            if (!tags[i].uuId) {
              // get the uuId
              tags[i].uuId = await tagService.list({filter: tags[i].name}).then((response) => {
                if (response.data.length !== 0) {
                  return response.data[0].uuId;
                }
                return null;
              });
              
              if (tags[i].uuId === null) {
                tags[i].uuId = await tagService.create([{name: tags[i].name}]).then((response) => {
                  if (response.data[response.data.jobCase].length !== 0) {
                    return response.data[response.data.jobCase][0].uuId;
                  }
                  return null;
                });
              }
            }
            
            if (tags[i].uuId !== null) {
              toAdd.push({uuId: tags[i].uuId});
            }
          }
          
          for (const tag of toAdd) {
            cmdList.push({
              "invoke": '/api/staff/link/tag/add',
              "body": {
                uuId: `@{${idName}}`,
                tagList: [{ uuId: tag.uuId }]
              }
            });
          }
        }
        
        if (cmdList.length >= 500) {
          // Try to limit the number of api calls sent to the server in each batch
          await compositeService.exec(cmdList)
          .then(response => {
            const feedbackList = response.data.feedbackList;
            if (Array.isArray(feedbackList) && 
                  feedbackList.length > 0 && 
                  feedbackList[0].uuId != null) {
              return feedbackList[0].uuId;
            }
          })
          .catch((e) => {
            this.httpAjaxError(e);
            return null;
          });
          cmdList = [];
        }
        
        percentage++;
        this.inProgressLabel = this.$t('staff.progress.importing', [parseFloat(percentage / items.length * 100).toFixed(0)]);
      }
    
      cmdList.push({
           "note":"Enable macro calculations"
          ,"invoke"    : "PUT /api/system/features?entity=macros&action=ENABLE"
      });
      await compositeService.exec(cmdList)
      .then(response => {
        const feedbackList = response.data.feedbackList;
        if (Array.isArray(feedbackList) && 
              feedbackList.length > 0 && 
              feedbackList[0].uuId != null) {
          return feedbackList[0].uuId;
        }
      })
      .catch((e) => {
        this.httpAjaxError(e);
        return null;
      });
    },
    docImportCancel() {
      this.docImportShow = false;
    },
    docImportGenericCancel() {
      this.docImportGenericShow = false;
    },
    onInactiveStaffChange() {
      if (this.alertMsg === this.$t('staff.error.not_found')) {
        this.alertMsg = null;
        this.selected = cloneDeep(this.selectedStaff);
      }
      this.settings.inactiveStaff = this.inactiveStaff;
      this.updateViewProfile();
      this.$nextTick(() => {
        if(this.gridOptions.api) {
          this.gridOptions.api.refreshServerSide({ purge: true });
        }
      });
    },
    onOrgChartActiveStaffChange() {
      //Don't update profile when toggled by AutoAssignStaff
      if (!this.toggledByAutoAssignStaff) {
        this.settings.orgChartActiveStaff = this.orgChartActiveStaff;
        this.updateViewProfile();
      }
      
      this.reloadOrgChart();
    },
    onOrgChartInactiveStaffChange() {
      //Don't update profile when toggled by AutoAssignStaff
      if (!this.toggledByAutoAssignStaff) {
        this.settings.orgChartInactiveStaff = this.orgChartInactiveStaff;
        this.updateViewProfile();
      }
      this.reloadOrgChart();
    },
    onOrgChartRealStaffChange() {
      //Don't update profile when toggled by AutoAssignStaff
      if (!this.toggledByAutoAssignStaff) {
        this.settings.orgChartRealStaff = this.orgChartRealStaff;
        this.updateViewProfile();
      }
      this.reloadOrgChart();
    },
    onOrgChartGenericStaffChange() {
      //Don't update profile when toggled by AutoAssignStaff
      if (!this.toggledByAutoAssignStaff) {
        this.settings.orgChartGenericStaff = this.orgChartGenericStaff;
        this.updateViewProfile();
      }
      this.reloadOrgChart();
    },
    onColoringOver() {
      this.$refs.coloring.visible = true;
    },
    onColoringLeave() {
      this.$refs.coloring.visible = false;
    },
    onColoringOverGeneric() {
      this.$refs.coloring_generic.visible = true;
    },
    onColoringLeaveGeneric() {
      this.$refs.coloring_generic.visible = false;
    },
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    onColorChange(val, color_key) {
      const coloring = color_key === 'staff_selector_coloring' ? this.coloring : this.generic.coloring;
      for (const key of Object.keys(coloring)) {
        coloring[key] = false;
      }
      coloring[val] = true;
      this.settings[color_key] = coloring;
      this.updateViewProfile();
      if (color_key === 'staff_selector_coloring') {
        this.gridOptions.api.redrawRows();
      }
      else {
        this.generic.gridOptions.api.redrawRows();
      }
    },
    getRowColor(data, params) {
      const tabName = params.tabName != null? params.tabName : 'list';
      const _coloring = tabName == 'list'? this.coloring : this[tabName].coloring;
      if (data &&
        data.color &&
        _coloring.staff) {
        return data.color;
      }
      else if (data &&
        data.companyColor &&
        data.companyColor.length > 0 &&
        data.companyColor[0] !== '' &&
        _coloring.company) {
        return data.companyColor[0];
      }
      else if (data &&
        data.departmentColor &&
        data.departmentColor.length > 0 &&
        data.departmentColor[0] !== '' &&
        _coloring.department) {
        return data.departmentColor[0];
      }
      else if (data &&
        data.locationColor &&
        data.locationColor.length > 0 &&
        data.locationColor[0] !== '' &&
        _coloring.location) {
        return data.locationColor[0];
      }
      else if (data &&
        data.skillColor &&
        data.skillColor.length > 0 &&
        data.skillColor[0] !== '' &&
        _coloring.skill) {
        return data.skillColor[0];
      }
      else if (data &&
        data.resourceColor &&
        data.resourceColor.length > 0 &&
        data.resourceColor[0] !== '' &&
        _coloring.resource) {
        return data.resourceColor[0];
      }
    },
    async onBookings() {
      this.settings['staff_selector_bookings'] = this.bookings;
      this.updateViewProfile();
      
      // reload all lists
      this.gridOptions.api.refreshServerSide({ purge: true });
      this.reloadOrgChart();
      if (this.recommended.gridOptions.api) {
        await this.prepareStaffList()
        .catch(e => {
          if (e != null && e.response != null && e.response.status == 403) {
            self.showNoRowsOverlay(self, self.$t('entity_selector.error.insufficient_permission_to_show_data'))
          } else {
            console.log(e); //eslint-disable-line no-console
          }
        });
        this.recommended.gridOptions.api.refreshServerSide({ purge: true });
      }
      if (this.generic.gridOptions.api) {
        this.generic.gridOptions.api.refreshServerSide({ purge: true });
      }
    },
    rectifyInvalidRecommendedSettings() {
      for (const prop in this.recommended_settings_template) {
        if (typeof this.recommended.settings[prop] != 'boolean') {
          this.recommended.settings[prop] = this.recommended_settings_template[prop];
        }
      }
    },
    prepareNoRowsMessage(self) {
      return () => {
          if (self.noRowsMessage != null) {
          return self.noRowsMessage;  
        }
        return this.$t('staff.grid.no_data');
      }
    },
    showNoRowsOverlay(self, msg=null) {
      self.noRowsMessage = msg
      if (self.gridOptions != null && self.gridOptions.api != null) {
        self.gridOptions.api.hideOverlay()
        setTimeout(() => {
          self.gridOptions.api.showNoRowsOverlay()
        })
      }
    },
    lackOfMandatoryField(mandatoryFields) {
      const _mandatoryFields = mandatoryFields != null? mandatoryFields : this.queryMandatoryFields
      const denyRulesMap = {
        'STAFF': getPermissionDenyProperties('STAFF', 'VIEW')
      }
      for (const field of _mandatoryFields) {
        const tokens = field.split('.')
        if (tokens.length == 1) {
          if (denyRulesMap.STAFF.includes(tokens[tokens.length-1])) {
            return true
          }
        } else {
          let linkedEntity = tokens[tokens.length - 2]
          let properties = [tokens[tokens.length - 1]]
          if (linkedEntity.indexOf('-') > -1) {
            const subTokens = linkedEntity.split('-')
            linkedEntity = subTokens[0]
            properties = [linkedEntity, `${subTokens[1]}.${properties[0]}`]
          }
          
          if (!Object.hasOwn(denyRulesMap, linkedEntity)) {
            denyRulesMap[linkedEntity] = getPermissionDenyProperties(linkedEntity, 'VIEW')
          }
          for (const property of properties) {
            if (denyRulesMap[linkedEntity].includes(property)) {
              return true
            }
          }
        }
      }
      return false
    }, 
    getParentCompanyColor(params) {
      if (params.data &&
          params.data.companyColor &&
          params.data.companyColor.length > 0 &&
          params.data.companyColor[0] !== '') {
        return params.data.companyColor[0];   
      }
      else if (params.node && params.node.parent) {
        return this.getParentCompanyColor(params.node.parent);
      }
      return null;
    }
  }
}


</script>

<style lang="scss" scoped>
.selector-nav {
  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  a {
    color: var(--projectal-orange);
    text-decoration: none;
    background-color: transparent;
  }
}

.bookings-switch {
  position: absolute;
  right: 20px;
  top: 24px;
  z-index: 1;
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .selector-nav {
    position: relative;
    top: 5px;
  }
  
  .bookings-switch {
    top: 60px;
  }
  
  .staff-tab-container {
    top: 15px;
    position: relative;
  }
}
</style>
<style lang="scss">
.staff-tab-container ul[role=tablist] {
  display: none; //Hide nav bar generated by bootstrapVue tabs component. 
}
</style>
<style type="text/css">
.staff-alert {
  z-index: 10;
}

.staff-tabs {
  padding: 0 3px;
}

.settings-button {
  margin-right: 5px !important;
  margin-left: auto !important;
  display: block !important;
}

.grid-toolbar {
  display: flex;
  align-items: center;
}


.select-state {
  display: inline-block;
      margin-left: 12px;
}

::v-deep .ag-theme-balham .ag-row-selected {
  background-color: transparent;
  background-color: var(--ag-selected-row-background-color, transparent);
}
</style>