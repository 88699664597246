var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" }, attrs: { id: _vm.id } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": [_vm.id],
          },
          on: { ok: _vm.ok, hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _vm.allowSelect
                    ? [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: _vm.disableOk && !_vm.allowNone,
                              size: "sm",
                              variant: "success",
                            },
                            on: {
                              click: function ($event) {
                                return ok()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.ok")))]
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$i18n.t(
                            "MANAGE" === _vm.mode
                              ? "button.close"
                              : "button.cancel"
                          )
                        )
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _vm.alertMsg != null
            ? _c("AlertFeedback", {
                staticClass: "staff-alert",
                attrs: {
                  msg: _vm.alertMsg,
                  details: _vm.alertMsgDetails.list,
                  detailTitle: _vm.alertMsgDetails.title,
                  alertState: _vm.alertState,
                },
                on: { resetAlert: _vm.resetAlert },
              })
            : _vm._e(),
          _vm.projectIds.length !== 0
            ? _c(
                "b-form-checkbox",
                {
                  staticClass: "bookings-switch",
                  attrs: {
                    id: "bookings-switch",
                    name: "bookings-switch ",
                    switch: "",
                  },
                  on: { change: _vm.onBookings },
                  model: {
                    value: _vm.bookings,
                    callback: function ($$v) {
                      _vm.bookings = $$v
                    },
                    expression: "bookings",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("staff.project_bookings")))]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "selector-navbar" },
            [
              _c(
                "PriorityNavigation",
                {
                  ref: "selector-nav",
                  staticClass: "selector-nav",
                  attrs: { ulClassName: "nav nav-pills" },
                },
                [
                  _c(
                    "li",
                    {
                      staticClass: "nav-pills nav-link active",
                      attrs: { name: "list" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.selectorNavClick.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("a", { attrs: { href: "#", target: "_self" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.allowSelect && _vm.listSelectedCount > 0
                              ? _vm.$t("staff.selector.list_w_count", {
                                  count: _vm.listSelectedCount,
                                })
                              : _vm.$t("staff.selector.list")
                          )
                        ),
                      ]),
                    ]
                  ),
                  this.taskUuId != null
                    ? _c(
                        "li",
                        {
                          staticClass: "nav-pills nav-link",
                          attrs: { name: "recommended" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.selectorNavClick.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("a", { attrs: { href: "#", target: "_self" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.allowSelect &&
                                  _vm.recommendedSelectedCount > 0
                                  ? _vm.$t(
                                      "staff.selector.recommended_w_count",
                                      { count: _vm.recommendedSelectedCount }
                                    )
                                  : _vm.$t("staff.selector.recommended")
                              )
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "li",
                    {
                      staticClass: "nav-pills nav-link",
                      attrs: { name: "orgChart" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.selectorNavClick.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("a", { attrs: { href: "#", target: "_self" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.allowSelect && _vm.orgChartSelectedCount > 0
                              ? _vm.$t("staff.selector.orgChart_w_count", {
                                  count: _vm.orgChartSelectedCount,
                                })
                              : _vm.$t("staff.selector.orgChart")
                          )
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "nav-pills nav-link",
                      attrs: { name: "generic" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.selectorNavClick.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("a", { attrs: { href: "#", target: "_self" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.allowSelect && _vm.genericSelectedCount > 0
                              ? _vm.$t("staff.selector.generic_w_count", {
                                  count: _vm.genericSelectedCount,
                                })
                              : _vm.$t("staff.selector.generic")
                          )
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-tabs",
            {
              staticClass: "staff-tab-container",
              attrs: {
                "active-nav-item-class": "active",
                "content-class": "staff-tabs mt-3 specific-list",
                pills: "",
              },
              on: { "activate-tab": _vm.onTabSwitch },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c(
                "b-tab",
                { attrs: { title: "List", "title-item-class": "mytab" } },
                [
                  _c("ListFilter", {
                    attrs: { termOnly: true, term: _vm.searchFilterTerm },
                    on: { applyFilter: _vm.applyFilter },
                  }),
                  _vm.allowManage
                    ? _c(
                        "PriorityNavigation",
                        { staticClass: "list-toolbar grid-toolbar border" },
                        [
                          !_vm.enablePagination
                            ? _c(
                                "li",
                                { staticClass: "d-inline-block" },
                                [
                                  _c("b-form-checkbox", {
                                    staticClass: "select-state",
                                    attrs: {
                                      id: "BTN_SELECT_" + _vm.id,
                                      indeterminate:
                                        _vm.select_state.indeterminate,
                                    },
                                    on: { change: _vm.selectionChanged },
                                    model: {
                                      value: _vm.select_state.checked,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.select_state,
                                          "checked",
                                          $$v
                                        )
                                      },
                                      expression: "select_state.checked",
                                    },
                                  }),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: "BTN_SELECT_" + _vm.id,
                                        triggers: "hover",
                                        placement: "top",
                                        boundary: "viewport",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.select")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canAdd()
                            ? _c(
                                "li",
                                { attrs: { id: "BTN_ADD_" + _vm.id } },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.modalOpen(true)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        style: {
                                          color: "var(--grid-toolbar-button)",
                                        },
                                        attrs: { icon: ["far", "plus"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: "BTN_ADD_" + _vm.id,
                                        triggers: "hover",
                                        placement: "top",
                                        boundary: "viewport",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("button.add")) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canView()
                            ? _c(
                                "li",
                                { attrs: { id: "BTN_EDIT_" + _vm.id } },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: { disabled: _vm.disableEdit },
                                      on: {
                                        click: function ($event) {
                                          return _vm.modalOpen(
                                            false,
                                            _vm.selected
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["far", "pen-to-square"],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: "BTN_EDIT_" + _vm.id,
                                        triggers: "hover",
                                        placement: "top",
                                        boundary: "viewport",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.edit")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canAdd()
                            ? _c(
                                "li",
                                { attrs: { id: "BTN_DUPLICATE_" + _vm.id } },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: { disabled: _vm.disableDuplicate },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showDuplicateDialog(
                                            _vm.selected
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "clone"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: "BTN_DUPLICATE_" + _vm.id,
                                        triggers: "hover",
                                        placement: "top",
                                        boundary: "viewport",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.duplicate")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canDelete()
                            ? _c(
                                "li",
                                { attrs: { id: "BTN_DELETE_" + _vm.id } },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: { disabled: _vm.disableDelete },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rowDelete(_vm.selected)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "trash-can"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: "BTN_DELETE_" + _vm.id,
                                        triggers: "hover",
                                        placement: "top",
                                        boundary: "viewport",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.delete")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canAdd()
                            ? _c(
                                "li",
                                {
                                  attrs: {
                                    id: "BTN_IMPORT_DOCUMENT_" + _vm.id,
                                  },
                                },
                                [
                                  _c(
                                    "b-btn",
                                    { on: { click: _vm.fileImport } },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "inbox-in"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: "BTN_IMPORT_DOCUMENT_" + _vm.id,
                                        triggers: "hover",
                                        placement: "top",
                                        boundary: "viewport",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "staff.button.import_document"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "li",
                            { attrs: { id: "BTN_EXPORT_DOCUMENT_" + _vm.id } },
                            [
                              _c(
                                "b-btn",
                                { on: { click: _vm.fileExport } },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "inbox-out"] },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: "BTN_EXPORT_DOCUMENT_" + _vm.id,
                                    triggers: "hover",
                                    placement: "top",
                                    boundary: "viewport",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("staff.button.export_document")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            {
                              on: _vm._d({ mouseleave: _vm.onColoringLeave }, [
                                _vm.colorMouseEnterEvent,
                                _vm.onColoringOver,
                              ]),
                            },
                            [
                              _c(
                                "b-dropdown",
                                {
                                  ref: "coloring",
                                  staticClass: "action-bar-dropdown",
                                  attrs: {
                                    id: "BTN_COLORING_" + _vm.id,
                                    "toggle-class": "text-decoration-none",
                                    "no-caret": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "button-content",
                                        fn: function () {
                                          return [
                                            _c("font-awesome-icon", {
                                              attrs: {
                                                icon: ["far", "palette"],
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1244906976
                                  ),
                                },
                                [
                                  _c(
                                    "b-dropdown-group",
                                    { attrs: { header: _vm.$t("colorby") } },
                                    [
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onColorChange(
                                                "none",
                                                "staff_selector_coloring"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "action-item-label",
                                            },
                                            [_vm._v(_vm._s(_vm.$t("none")))]
                                          ),
                                          _vm.coloring.none
                                            ? _c("font-awesome-icon", {
                                                staticClass: "active-check",
                                                attrs: {
                                                  icon: ["far", "check"],
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onColorChange(
                                                "staff",
                                                "staff_selector_coloring"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "action-item-label",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("staff.coloring.staff")
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm.coloring.staff
                                            ? _c("font-awesome-icon", {
                                                staticClass: "active-check",
                                                attrs: {
                                                  icon: ["far", "check"],
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onColorChange(
                                                "company",
                                                "staff_selector_coloring"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "action-item-label",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "staff.coloring.company"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm.coloring.company
                                            ? _c("font-awesome-icon", {
                                                staticClass: "active-check",
                                                attrs: {
                                                  icon: ["far", "check"],
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onColorChange(
                                                "department",
                                                "staff_selector_coloring"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "action-item-label",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "staff.coloring.department"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm.coloring.department
                                            ? _c("font-awesome-icon", {
                                                staticClass: "active-check",
                                                attrs: {
                                                  icon: ["far", "check"],
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onColorChange(
                                                "location",
                                                "staff_selector_coloring"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "action-item-label",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "staff.coloring.location"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm.coloring.location
                                            ? _c("font-awesome-icon", {
                                                staticClass: "active-check",
                                                attrs: {
                                                  icon: ["far", "check"],
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onColorChange(
                                                "skill",
                                                "staff_selector_coloring"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "action-item-label",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("staff.coloring.skill")
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm.coloring.skill
                                            ? _c("font-awesome-icon", {
                                                staticClass: "active-check",
                                                attrs: {
                                                  icon: ["far", "check"],
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onColorChange(
                                                "resource",
                                                "staff_selector_coloring"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "action-item-label",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "staff.coloring.resource"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm.coloring.resource
                                            ? _c("font-awesome-icon", {
                                                staticClass: "active-check",
                                                attrs: {
                                                  icon: ["far", "check"],
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("li", [
                            _c(
                              "span",
                              {
                                staticClass: "action-toolbar-divider",
                                attrs: { readonly: "" },
                              },
                              [_vm._v("|")]
                            ),
                          ]),
                          _c(
                            "li",
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  on: { change: _vm.onActiveStaffChange },
                                  model: {
                                    value: _vm.activeStaff,
                                    callback: function ($$v) {
                                      _vm.activeStaff = $$v
                                    },
                                    expression: "activeStaff",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("staff.toggle_active")))]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-2",
                                  on: { change: _vm.onInactiveStaffChange },
                                  model: {
                                    value: _vm.inactiveStaff,
                                    callback: function ($$v) {
                                      _vm.inactiveStaff = $$v
                                    },
                                    expression: "inactiveStaff",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("staff.toggle_inactive"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("ag-grid-vue", {
                    staticClass:
                      "ag-theme-balham selector-grid-height-with-tabs",
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "staff-grid",
                      gridOptions: _vm.gridOptions,
                      columnDefs: _vm.columnDefs,
                      context: _vm.context,
                      defaultColDef: _vm.defaultColDef,
                      pagination: _vm.enablePagination,
                      paginationPageSize: 1000,
                      cacheBlockSize: 10000,
                      rowData: _vm.rowData,
                      rowModelType: "serverSide",
                      rowSelection: _vm.multiple ? "multiple" : "single",
                      rowMultiSelectWithClick: "",
                      overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                      serverSideInfiniteScroll: true,
                      sideBar: false,
                      suppressDragLeaveHidesColumns: "",
                      suppressCellFocus: "",
                      suppressContextMenu: "",
                      suppressMultiSort: "",
                      noRowsOverlayComponent: "noRowsOverlay",
                      noRowsOverlayComponentParams:
                        _vm.noRowsOverlayComponentParams,
                    },
                    on: { "grid-ready": _vm.onGridReady },
                  }),
                ],
                1
              ),
              _vm.taskUuId !== null
                ? _c(
                    "b-tab",
                    {
                      attrs: {
                        title: "Recommended",
                        "title-item-class": "mytab",
                        "content-class": "recommended-list",
                      },
                    },
                    [
                      _c("ListFilter", {
                        attrs: {
                          termOnly: true,
                          term: _vm.recommended.searchFilterTerm,
                        },
                        on: { applyFilter: _vm.applyRecommendedFilter },
                      }),
                      _c(
                        "div",
                        { staticClass: "grid-toolbar border" },
                        [
                          _c(
                            "b-btn",
                            {
                              staticClass: "settings-button",
                              attrs: { id: "recommendation_settings" },
                              on: {
                                click: function ($event) {
                                  return _vm.showRecommendationSettings()
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "gear"] },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: "recommendation_settings",
                                triggers: "hover",
                                placement: "top",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("staff.recommendation_settings")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("ag-grid-vue", {
                        staticClass:
                          "ag-theme-balham selector-grid-height-with-tabs",
                        staticStyle: { width: "100%" },
                        attrs: {
                          id: "recommended-grid",
                          gridOptions: _vm.recommended.gridOptions,
                          columnDefs: _vm.recommended.columnDefs,
                          context: _vm.recommended.context,
                          defaultColDef: _vm.recommended.defaultColDef,
                          pagination: "",
                          paginationPageSize: 1000,
                          rowModelType: "serverSide",
                          rowSelection: _vm.multiple ? "multiple" : "single",
                          rowMultiSelectWithClick: "",
                          overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                          serverSideInfiniteScroll: true,
                          sideBar: false,
                          suppressDragLeaveHidesColumns: "",
                          suppressCellFocus: "",
                          suppressMultiSort: "",
                          noRowsOverlayComponent: "noRowsOverlay",
                          noRowsOverlayComponentParams:
                            _vm.generic.noRowsOverlayComponentParams,
                        },
                        on: { "grid-ready": _vm.onRecommendedGridReady },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-tab",
                {
                  attrs: {
                    title: "Org Chart",
                    "title-item-class": "mytab",
                    "content-class": "orgchart-list",
                  },
                },
                [
                  _c("ListFilter", {
                    attrs: {
                      termOnly: true,
                      term: _vm.orgChart.searchFilterTerm,
                    },
                    on: { applyFilter: _vm.applyOrgChartFilter },
                  }),
                  _vm.allowManage
                    ? _c(
                        "PriorityNavigation",
                        { staticClass: "orgchart-toolbar grid-toolbar border" },
                        [
                          _c(
                            "li",
                            {
                              attrs: { id: "BTN_ADD_" + _vm.id + "_ORGCHART" },
                            },
                            [
                              _vm.canAdd()
                                ? _c(
                                    "b-btn",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.modalOpen(true)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        style: {
                                          color: "var(--grid-toolbar-button)",
                                        },
                                        attrs: { icon: ["far", "plus"] },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: "BTN_ADD_" + _vm.id + "_ORGCHART",
                                    triggers: "hover",
                                    placement: "top",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("button.add")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.canView()
                            ? _c(
                                "li",
                                {
                                  attrs: {
                                    id: "BTN_EDIT_" + _vm.id + "_ORGCHART",
                                  },
                                },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.orgChart.disableEdit,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.modalOpen(
                                            false,
                                            _vm.orgChart.selected
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["far", "pen-to-square"],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target:
                                          "BTN_EDIT_" + _vm.id + "_ORGCHART",
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.edit")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canAdd()
                            ? _c(
                                "li",
                                {
                                  attrs: {
                                    id: "BTN_DUPLICATE_" + _vm.id + "_ORGCHART",
                                  },
                                },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.orgChart.disableDuplicate,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showDuplicateDialogSelectedObj(
                                            _vm.orgChart.selected,
                                            _vm.orgChart.gridOptions.api
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "clone"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target:
                                          "BTN_DUPLICATE_" +
                                          _vm.id +
                                          "_ORGCHART",
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.duplicate")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canDelete()
                            ? _c(
                                "li",
                                {
                                  attrs: {
                                    id: "BTN_DELETE_" + _vm.id + "_ORGCHART",
                                  },
                                },
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.orgChart.disableDelete,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rowDelete(
                                            _vm.orgChart.selected
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "trash-can"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target:
                                          "BTN_DELETE_" + _vm.id + "_ORGCHART",
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.delete")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "li",
                            {
                              attrs: {
                                id:
                                  "BTN_EXPORT_DOCUMENT_" + _vm.id + "_ORGCHART",
                              },
                            },
                            [
                              _c(
                                "b-btn",
                                { on: { click: _vm.fileExportOrgChart } },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "inbox-out"] },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target:
                                      "BTN_EXPORT_DOCUMENT_" +
                                      _vm.id +
                                      "_ORGCHART",
                                    triggers: "hover",
                                    placement: "top",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("staff.button.export_document")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("li", [
                            _c(
                              "span",
                              {
                                staticClass: "action-toolbar-divider",
                                attrs: { readonly: "" },
                              },
                              [_vm._v("|")]
                            ),
                          ]),
                          _c(
                            "li",
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-2",
                                  on: { change: _vm.onOrgChartRealStaffChange },
                                  model: {
                                    value: _vm.orgChartRealStaff,
                                    callback: function ($$v) {
                                      _vm.orgChartRealStaff = $$v
                                    },
                                    expression: "orgChartRealStaff",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("staff.actual_staff")))]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-2",
                                  on: {
                                    change: _vm.onOrgChartGenericStaffChange,
                                  },
                                  model: {
                                    value: _vm.orgChartGenericStaff,
                                    callback: function ($$v) {
                                      _vm.orgChartGenericStaff = $$v
                                    },
                                    expression: "orgChartGenericStaff",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("staff.generic_staff")))]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-2",
                                  attrs: { disabled: !_vm.orgChartRealStaff },
                                  on: {
                                    change: _vm.onOrgChartActiveStaffChange,
                                  },
                                  model: {
                                    value: _vm.orgChartActiveStaff,
                                    callback: function ($$v) {
                                      _vm.orgChartActiveStaff = $$v
                                    },
                                    expression: "orgChartActiveStaff",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("staff.toggle_active")))]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "ml-2",
                                  attrs: { disabled: !_vm.orgChartRealStaff },
                                  on: {
                                    change: _vm.onOrgChartInactiveStaffChange,
                                  },
                                  model: {
                                    value: _vm.orgChartInactiveStaff,
                                    callback: function ($$v) {
                                      _vm.orgChartInactiveStaff = $$v
                                    },
                                    expression: "orgChartInactiveStaff",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("staff.toggle_inactive"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("ag-grid-vue", {
                    staticClass:
                      "ag-theme-balham selector-grid-height-with-tabs",
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "org-chart-grid",
                      gridOptions: _vm.orgChart.gridOptions,
                      columnDefs: _vm.orgChart.columnDefs,
                      autoGroupColumnDef: _vm.orgChart.autoGroupColumnDef,
                      context: _vm.orgChart.context,
                      defaultColDef: _vm.orgChart.defaultColDef,
                      rowData: _vm.orgChart.rowData,
                      rowSelection: _vm.multiple ? "multiple" : "single",
                      rowMultiSelectWithClick: "",
                      overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                      sideBar: false,
                      groupDefaultExpanded: "-1",
                      suppressDragLeaveHidesColumns: "",
                      suppressCellFocus: "",
                      treeData: "",
                      suppressContextMenu: "",
                      suppressMultiSort: "",
                      noRowsOverlayComponent: "noRowsOverlay",
                      noRowsOverlayComponentParams:
                        _vm.orgChart.noRowsOverlayComponentParams,
                    },
                    on: { "grid-ready": _vm.onOrgChartGridReady },
                  }),
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Generic", "title-item-class": "mytab" } },
                [
                  _c("ListFilter", {
                    attrs: {
                      termOnly: true,
                      term: _vm.generic.searchFilterTerm,
                    },
                    on: { applyFilter: _vm.applyGenericFilter },
                  }),
                  _vm.allowManage
                    ? _c("div", { staticClass: "grid-toolbar border" }, [
                        !_vm.enablePagination
                          ? _c(
                              "div",
                              { staticClass: "d-inline-block" },
                              [
                                _c("b-form-checkbox", {
                                  staticClass: "select-state",
                                  attrs: {
                                    id: "GENERIC_BTN_SELECT_" + _vm.id,
                                    indeterminate:
                                      _vm.generic.select_state.indeterminate,
                                  },
                                  on: { change: _vm.genericSelectionChanged },
                                  model: {
                                    value: _vm.generic.select_state.checked,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.generic.select_state,
                                        "checked",
                                        $$v
                                      )
                                    },
                                    expression: "generic.select_state.checked",
                                  },
                                }),
                                _c(
                                  "b-popover",
                                  {
                                    attrs: {
                                      target: "GENERIC_BTN_SELECT_" + _vm.id,
                                      triggers: "hover",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("button.select")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.canAdd()
                          ? _c(
                              "span",
                              { attrs: { id: "GENERIC_BTN_ADD_" + _vm.id } },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.modalOpen(true, null, true)
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      style: {
                                        color: "var(--grid-toolbar-button)",
                                      },
                                      attrs: { icon: ["far", "plus"] },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-popover",
                                  {
                                    attrs: {
                                      target: "GENERIC_BTN_ADD_" + _vm.id,
                                      triggers: "hover",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("button.add")) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.canView()
                          ? _c(
                              "span",
                              { attrs: { id: "GENERIC_BTN_EDIT_" + _vm.id } },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: {
                                      disabled: _vm.generic.disableEdit,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.modalOpen(
                                          false,
                                          _vm.generic.selected,
                                          true
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "pen-to-square"] },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-popover",
                                  {
                                    attrs: {
                                      target: "GENERIC_BTN_EDIT_" + _vm.id,
                                      triggers: "hover",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("button.edit")) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.canAdd()
                          ? _c(
                              "span",
                              {
                                attrs: {
                                  id: "GENERIC_BTN_DUPLICATE_" + _vm.id,
                                },
                              },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: {
                                      disabled: _vm.generic.disableDuplicate,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showDuplicateDialogForGeneric(
                                          _vm.generic.selected
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "clone"] },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-popover",
                                  {
                                    attrs: {
                                      target: "GENERIC_BTN_DUPLICATE_" + _vm.id,
                                      triggers: "hover",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("button.duplicate")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.canDelete()
                          ? _c(
                              "span",
                              { attrs: { id: "GENERIC_BTN_DELETE_" + _vm.id } },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: {
                                      disabled: _vm.generic.disableDelete,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.rowDelete(
                                          _vm.generic.selected
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "trash-can"] },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-popover",
                                  {
                                    attrs: {
                                      target: "GENERIC_BTN_DELETE_" + _vm.id,
                                      triggers: "hover",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("button.delete")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.canAdd()
                          ? _c(
                              "span",
                              {
                                attrs: {
                                  id: "GENERIC_BTN_IMPORT_DOCUMENT_" + _vm.id,
                                },
                              },
                              [
                                _c(
                                  "b-btn",
                                  { on: { click: _vm.fileImportGeneric } },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "inbox-in"] },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-popover",
                                  {
                                    attrs: {
                                      target:
                                        "GENERIC_BTN_IMPORT_DOCUMENT_" + _vm.id,
                                      triggers: "hover",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("staff.button.import_document")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "span",
                          {
                            attrs: {
                              id: "GENERIC_BTN_EXPORT_DOCUMENT_" + _vm.id,
                            },
                          },
                          [
                            _c(
                              "b-btn",
                              { on: { click: _vm.fileExportGeneric } },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "inbox-out"] },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target:
                                    "GENERIC_BTN_EXPORT_DOCUMENT_" + _vm.id,
                                  triggers: "hover",
                                  placement: "top",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("staff.button.export_document")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            on: _vm._d(
                              { mouseleave: _vm.onColoringLeaveGeneric },
                              [
                                _vm.colorMouseEnterEvent,
                                _vm.onColoringOverGeneric,
                              ]
                            ),
                          },
                          [
                            _c(
                              "b-dropdown",
                              {
                                ref: "coloring_generic",
                                staticClass: "action-bar-dropdown",
                                attrs: {
                                  id: "BTN_COLORING_" + _vm.id,
                                  "toggle-class": "text-decoration-none",
                                  "no-caret": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "button-content",
                                      fn: function () {
                                        return [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: ["far", "palette"] },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1244906976
                                ),
                              },
                              [
                                _c(
                                  "b-dropdown-group",
                                  { attrs: { header: _vm.$t("colorby") } },
                                  [
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onColorChange(
                                              "none",
                                              "staff_selector_generic_coloring"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "action-item-label" },
                                          [_vm._v(_vm._s(_vm.$t("none")))]
                                        ),
                                        _vm.generic.coloring.none
                                          ? _c("font-awesome-icon", {
                                              staticClass: "active-check",
                                              attrs: { icon: ["far", "check"] },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onColorChange(
                                              "staff",
                                              "staff_selector_generic_coloring"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "action-item-label" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("staff.coloring.staff")
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm.generic.coloring.staff
                                          ? _c("font-awesome-icon", {
                                              staticClass: "active-check",
                                              attrs: { icon: ["far", "check"] },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onColorChange(
                                              "company",
                                              "staff_selector_generic_coloring"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "action-item-label" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("staff.coloring.company")
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm.generic.coloring.company
                                          ? _c("font-awesome-icon", {
                                              staticClass: "active-check",
                                              attrs: { icon: ["far", "check"] },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onColorChange(
                                              "location",
                                              "staff_selector_generic_coloring"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "action-item-label" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "staff.coloring.location"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm.generic.coloring.location
                                          ? _c("font-awesome-icon", {
                                              staticClass: "active-check",
                                              attrs: { icon: ["far", "check"] },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onColorChange(
                                              "skill",
                                              "staff_selector_generic_coloring"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "action-item-label" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("staff.coloring.skill")
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm.generic.coloring.skill
                                          ? _c("font-awesome-icon", {
                                              staticClass: "active-check",
                                              attrs: { icon: ["far", "check"] },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-dropdown-item",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onColorChange(
                                              "resource",
                                              "staff_selector_generic_coloring"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "action-item-label" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "staff.coloring.resource"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm.generic.coloring.resource
                                          ? _c("font-awesome-icon", {
                                              staticClass: "active-check",
                                              attrs: { icon: ["far", "check"] },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("ag-grid-vue", {
                    staticClass:
                      "ag-theme-balham selector-grid-height-with-tabs",
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "generic-staff-grid",
                      gridOptions: _vm.generic.gridOptions,
                      columnDefs: _vm.generic.columnDefs,
                      context: _vm.generic.context,
                      defaultColDef: _vm.generic.defaultColDef,
                      pagination: _vm.enablePagination,
                      paginationPageSize: 1000,
                      rowModelType: "serverSide",
                      rowSelection: _vm.multiple ? "multiple" : "single",
                      rowMultiSelectWithClick: "",
                      overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                      serverSideInfiniteScroll: true,
                      sideBar: false,
                      suppressDragLeaveHidesColumns: "",
                      suppressCellFocus: "",
                      suppressContextMenu: "",
                      suppressMultiSort: "",
                      noRowsOverlayComponent: "noRowsOverlay",
                      noRowsOverlayComponentParams:
                        _vm.generic.noRowsOverlayComponentParams,
                    },
                    on: { "grid-ready": _vm.onGenericReady },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.staffShow
        ? _c("StaffModal", {
            attrs: {
              id: _vm.staffId,
              show: _vm.staffShow,
              title: _vm.staffTitle,
              isGeneric: _vm.isGeneric,
            },
            on: {
              "update:show": function ($event) {
                _vm.staffShow = $event
              },
              success: _vm.modalSuccess,
            },
          })
        : _vm._e(),
      _c("RecommendationSettingsModal", {
        attrs: { userId: _vm.userId, show: _vm.recommendationSettingsShow },
        on: {
          "update:show": function ($event) {
            _vm.recommendationSettingsShow = $event
          },
          success: _vm.recommendationSettingsSuccess,
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.duplicateTitle,
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { hidden: _vm.duplicateCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _vm.duplicateInProgress
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: "",
                            size: "sm",
                            variant: "success",
                          },
                        },
                        [
                          _c("b-spinner", {
                            attrs: { small: "", type: "grow" },
                          }),
                          _vm._v(_vm._s(_vm.$t("button.processing"))),
                        ],
                        1
                      )
                    : _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: { click: _vm.duplicateOk },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.duplicate")))]
                      ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.duplicateShow,
            callback: function ($$v) {
              _vm.duplicateShow = $$v
            },
            expression: "duplicateShow",
          },
        },
        [
          _vm.isGeneric
            ? [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.$t("staff.field.name"),
                      "label-for": "duplicateName",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "duplicateName",
                            type: "text",
                            "data-vv-as": _vm.$t("staff.field.name"),
                            "data-vv-name": "duplicateName",
                            "data-vv-delay": "500",
                          },
                          model: {
                            value: _vm.duplicateName,
                            callback: function ($$v) {
                              _vm.duplicateName = $$v
                            },
                            expression: "duplicateName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-invalid-feedback",
                      {
                        staticClass: "alert-danger form-field-alert",
                        class: { "d-block": _vm.showDuplicateNameError },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "circle-exclamation"] },
                        }),
                        _vm._v(
                          "  " + _vm._s(_vm.errors.first("duplicateName")) + " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.$t("staff.field.email"),
                      "label-for": "duplicateEmail",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "duplicateEmail",
                            type: "text",
                            "data-vv-as": _vm.$t("staff.field.email"),
                            "data-vv-name": "duplicateEmail",
                            "data-vv-delay": "500",
                          },
                          model: {
                            value: _vm.duplicateEmail,
                            callback: function ($$v) {
                              _vm.duplicateEmail = $$v
                            },
                            expression: "duplicateEmail",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-invalid-feedback",
                      {
                        staticClass: "alert-danger form-field-alert",
                        class: { "d-block": _vm.showDuplicateEmailError },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "circle-exclamation"] },
                        }),
                        _vm._v(
                          "  " +
                            _vm._s(_vm.errors.first("duplicateEmail")) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.$t("staff.field.firstName"),
                      "label-for": "duplicateFirstname",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: { id: "duplicateFirstname", type: "text" },
                          model: {
                            value: _vm.duplicateFirstname,
                            callback: function ($$v) {
                              _vm.duplicateFirstname = $$v
                            },
                            expression: "duplicateFirstname",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: _vm.$t("staff.field.lastName"),
                      "label-for": "duplicatLastname",
                    },
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "duplicatLastname",
                            type: "text",
                            "data-vv-as": _vm.$t("staff.field.lastName"),
                            "data-vv-name": "duplicateLastname",
                            "data-vv-delay": "500",
                          },
                          model: {
                            value: _vm.duplicateLastname,
                            callback: function ($$v) {
                              _vm.duplicateLastname = $$v
                            },
                            expression: "duplicateLastname",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-invalid-feedback",
                      {
                        staticClass: "alert-danger form-field-alert",
                        class: { "d-block": _vm.showDuplicateLastnameError },
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "circle-exclamation"] },
                        }),
                        _vm._v(
                          "  " +
                            _vm._s(_vm.errors.first("duplicateLastname")) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("staff.confirmation.title_delete"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteShow,
            callback: function ($$v) {
              _vm.confirmDeleteShow = $$v
            },
            expression: "confirmDeleteShow",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-block" },
            [
              _vm.selectedWithUser.length > 0
                ? [
                    _vm.selectedWithUser.length > 1
                      ? [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "staff.confirmation.delete_plural_user_link_warning_line1"
                                )
                              )
                            ),
                          ]),
                          _c(
                            "ul",
                            [
                              _vm._l(
                                _vm.selectedWithUser,
                                function (item, index) {
                                  return [
                                    _c("li", { key: index }, [
                                      _vm._v(_vm._s(item.userEmail[0])),
                                    ]),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "staff.confirmation.delete_plural_user_link_warning_line2"
                                )
                              )
                            ),
                          ]),
                        ]
                      : [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "staff.confirmation.delete_user_link_warning_line1",
                                  [_vm.selectedWithUser[0].userEmail[0]]
                                )
                              )
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "staff.confirmation.delete_user_link_warning_line2"
                                )
                              )
                            ),
                          ]),
                        ],
                  ]
                : _vm._e(),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      _vm.selected.length > 1
                        ? "staff.confirmation.delete_plural"
                        : "staff.confirmation.delete"
                    )
                  )
                ),
              ]),
            ],
            2
          ),
        ]
      ),
      _c("GanttImportDialog", {
        attrs: {
          properties: [
            { value: "color", text: _vm.$t("field.color") },
            { value: "company", text: _vm.$t("staff.field.company") },
            { value: "department", text: _vm.$t("staff.field.department") },
            { value: "email", text: _vm.$t("staff.field.email") },
            { value: "enddate", text: _vm.$t("staff.field.endDate") },
            { value: "firstname", text: _vm.$t("staff.field.firstName") },
            { value: "identifier", text: _vm.$t("field.identifier") },
            { value: "name", text: _vm.$t("staff.field.lastName") },
            { value: "location", text: _vm.$t("staff.field.location") },
            { value: "notes", text: _vm.$t("staff.field.notes") },
            { value: "payamount", text: _vm.$t("staff.field.payAmount") },
            { value: "paycurrency", text: _vm.$t("staff.field.payCurrency") },
            { value: "payfrequency", text: _vm.$t("staff.field.payFrequency") },
            { value: "phone", text: _vm.$t("staff.field.phones") },
            { value: "position", text: _vm.$t("staff.field.position") },
            { value: "resources", text: _vm.$t("staff.field.resources") },
            { value: "skills", text: _vm.$t("staff.field.skills") },
            { value: "socials", text: _vm.$t("staff.field.socials") },
            { value: "startdate", text: _vm.$t("staff.field.startDate") },
            { value: "tag", text: _vm.$t("field.tag") },
            { value: "type", text: _vm.$t("staff.field.type") },
            { value: "websites", text: _vm.$t("staff.field.websites") },
          ],
          mode: "STAFF",
          show: _vm.docImportShow,
          title: _vm.$t("staff.button.import_document"),
        },
        on: {
          "modal-ok": _vm.docImportOk,
          "modal-cancel": _vm.docImportCancel,
        },
      }),
      _c("GanttImportDialog", {
        attrs: {
          properties: [
            { value: "color", text: _vm.$t("field.color") },
            { value: "department", text: _vm.$t("staff.field.department") },
            { value: "identifier", text: _vm.$t("field.identifier") },
            { value: "location", text: _vm.$t("staff.field.location") },
            { value: "name", text: _vm.$t("staff.field.name") },
            { value: "payamount", text: _vm.$t("staff.field.payAmount") },
            { value: "paycurrency", text: _vm.$t("staff.field.payCurrency") },
            { value: "payfrequency", text: _vm.$t("staff.field.payFrequency") },
            { value: "skills", text: _vm.$t("staff.field.skills") },
            { value: "type", text: _vm.$t("staff.field.type") },
            { value: "resources", text: _vm.$t("staff.field.resources") },
            { value: "tag", text: _vm.$t("field.tag") },
          ],
          mode: "STAFF_GENERIC",
          show: _vm.docImportGenericShow,
          title: _vm.$t("staff.button.import_document"),
        },
        on: {
          "modal-ok": _vm.docImportGenericOk,
          "modal-cancel": _vm.docImportGenericCancel,
        },
      }),
      _c("InProgressModal", {
        attrs: {
          show: _vm.inProgressShow,
          label: _vm.inProgressLabel,
          isStopable: _vm.inProgressStoppable,
        },
        on: {
          "update:show": function ($event) {
            _vm.inProgressShow = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }